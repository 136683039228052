import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { MdOutlineLockClock } from 'react-icons/md';
import PID_MAP from "../../../../Assets/constants/inclusion_pid.json"

export default function DropDown({ col, pathname }) {

  const { title, links, icon, active } = col

  // eslint-disable-next-line no-unused-vars
  const [category, setCategory] = useState("other")
  const [cols, setCols] = useState([])
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    let selectedCategory = localStorage.getItem("category") || "other";
    setCategory(selectedCategory);

    let filteredData = links?.filter(col => col.categories.includes(selectedCategory));
    setCols(filteredData);

    if (filteredData?.length === 0) {
      selectedCategory = "other";
      setCategory(selectedCategory);
      filteredData = links?.filter(col => col.categories.includes(selectedCategory));
      setCols(filteredData);
    }
    setIsActive(links.find((link) => link.link === pathname ? true : false))

  }, [links, pathname]);



  const program_inclusion_pid = localStorage.getItem("program_id");
  const program_inclusive_id = PID_MAP[program_inclusion_pid];
  const term = parseInt(localStorage.getItem("term"));

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        margin: "0px !important",
        backgroundColor: "white",
        borderRadius: "4px",
      }}
      onChange={() => setIsActive(!isActive)}
      expanded={isActive}
      disableGutters={true}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          minHeight: "0",
          padding: "0",
          m: 0,
          "& .MuiAccordionSummary-content": {
            margin: 0,
          }
        }}
      >
        <div className="flex items-center gap-2 py-2 px-2">
          <div className="w-6 h-6 flex-shrink-0">
            <img className="w-full h-full object-contain" src={isActive ? active : icon} alt={title} />
          </div>
          <h1 className={`text-sm font-semibold w-full h-full ${isActive ? "text-black" : "text-neutral-500"}`}>{title}</h1>
        </div>

      </AccordionSummary>
      <AccordionDetails
        sx={{
          margin: "0px !important"
        }}
      >
        <ol className='flex flex-col gap-1 list-none'>
          {cols?.map((link, index) => (
            <li key={index}>
              {link.type === "link" && link.title !== "Term Projects" ? (
                <div className={`w-full py-2 px-2 rounded-sm text-start transition-all duration-150 hover:bg-[#E6F2FF] hover:text-black ${pathname === link.link ? "bg-[#E6F2FF] text-black" : "text-neutral-500"}`}>
                  <Link className='w-full' to={link.link} key={index}>
                    <h1 className="text-sm text-nowrap font-semibold w-full h-full">{link.title}</h1>
                  </Link>
                </div>
              ) : (link.type === "link" && link.title === "Term Projects" && term >= 4) ? (
                <div className={`w-full py-2 px-2 rounded-sm text-start transition-all duration-150 hover:bg-[#E6F2FF] hover:text-black ${pathname === link.link ? "bg-[#E6F2FF] text-black" : "text-neutral-500"}`}>
                <Link className='w-full' to={link.link} key={index}>
                  <h1 className="text-sm text-nowrap font-semibold w-full h-full">{link.title}</h1>
                </Link>
                </div>
              ) : link.type === "external" && link.title !== "Program Inclusion" ? (
                <div className={`w-full py-2 px-2 rounded-sm text-start transition-all duration-150 hover:bg-[#E6F2FF] hover:text-black ${pathname === link.link ? "bg-[#E6F2FF] text-black" : "text-neutral-500"}`}>
                <a target="_blank" rel="noreferrer" href={`${link.link}`}>
                  <h1 className="text-sm text-nowrap font-semibold w-full h-full">{link.title}</h1>
                </a>
                </div>
              ) : link.type === "external" && link.title === "Program Inclusion" ? (
                <div className={`w-full py-2 px-2 rounded-sm text-start transition-all duration-150 hover:bg-[#E6F2FF] hover:text-black ${pathname === link.link ? "bg-[#E6F2FF] text-black" : "text-neutral-500"}`}>
                <a target="_blank" rel="noreferrer" href={`${link.link}/${program_inclusive_id}`}>
                  <h1 className="text-sm text-nowrap font-semibold w-full h-full">{link.title}</h1>
                </a>
                </div>
              ) : link.type === "locked" ? (
                <div className={`w-full py-2 px-2 rounded-sm text-start transition-all duration-150 hover:bg-[#E6F2FF] hover:text-black ${pathname === link.link ? "bg-[#E6F2FF] text-black" : "text-neutral-500"}`}>
                <button className="text-sm w-full text-start" disabled>
                  <div className="flex items-center gap-2 justify-between rounded">
                    <h1 className="text-sm font-semibold w-full h-full">{link.title}</h1>
                    <MdOutlineLockClock size={20} className="cursor-pointer" title="At the moment, the feature is inactive for your account." />
                  </div>
                </button>
                </div>
              ) : null}
            </li>
          ))}
        </ol>
      </AccordionDetails>
    </Accordion>
  );
}