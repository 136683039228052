import React, { useState, useEffect } from "react";
import { Typography, Button, Box, Paper, Container, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LoginDashboard from "../Navbar/MobileSidebar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { InlineWidget } from "react-calendly";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import MinimizeIcon from "@mui/icons-material/Minimize";
import Sidbar from "./DesktopSidebar";
import AuthSidebar from "../../Auth/AuthSidebar";
const CareerCounselling = () => {
  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategory] = useState("");
  // Use for Navigate on Previous
  let navigate = useNavigate();
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategory(category);
    //console.log(user_id, enrol_id, category);
  }, []);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // if (user == null) {
  //  window.location.href="https://accredian.com/Login"
  // }
  return (
    <>
      <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 7 }}>
        <Box sx={{ pt: 8 }}>
          <Grid container>
          <Grid item lg={3} sx={{display:{xs:"none",lg:"block"}}}>
              <Sidbar />
              {/* <AuthSidebar/> */}
            </Grid>
            <Grid item lg={9}>

              <Link
                to="/Dashboard"
                style={{ textDecoration: "none", color: "#000" }}
              >
                <Box display="flex">
                  <ArrowBackIcon sx={{ fontWeight: "bold" }} />
                  <Typography
                    sx={{ ml: 2, fontWeight: "bold", fontSize: "1rem" }}
                  >
                    {" "}
                    Back to Dashboard
                  </Typography>
                </Box>
              </Link>

      <>
              <Box sx={{mb:{xs:10},px:{xs:2}}}>
                <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Career Counselling Center
                </Typography>
                <Typography sx={{ fontSize: "1.3rem" }}>
                  Schedule your one-on-one with Accredian Career Mentor
                </Typography>
              </Box>
              <Box sx={{mb:{xs:5},px:{xs:2,lg:0}}}>
                <InlineWidget
                  url="https://calendly.com/insaid-1/accredian-student-counselling-clone?month=2023-11"
                  styles={{
                    height: "828px",
                    marginTop: "-60px",
                  }}
                />
              </Box>
              <Box sx={{px:{xs:2}}}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    marginBottom: "0.5rem !important",
                  }}
                >
                  What is the significance of Career Assistance and what is the
                  objective of the counseling sessions?
                </Typography>
                <Typography sx={{ fontSize: "1rem" }}>
                  Career Assistance enables students to interact with the Career
                  Coach for personalized guidance in understanding the profile
                  gaps and mapping their Career journey ahead. The sessions have
                  predefined objectives to assist students in their learning
                  journey.
                </Typography>
              </Box>
              <Box sx={{px:{xs:2}}}>
                <Typography
                  sx={{
                    fontSize: "0.97rem",
                    fontWeight: "bold",
                    marginBottom: "0.5rem !important",
                  }}
                >
                  Objective of Career Assistance?
                </Typography>
                <Typography
                  sx={{ fontSize: "0.8rem", marginBottom: "0.5rem !important" }}
                >
                  <FiberManualRecordIcon sx={{ fontSize: "0.6rem", mr: 0.8 }} />
                  Enable you to map your <b>Career Progression</b> ahead
                </Typography>
                <Typography
                  sx={{ fontSize: "0.8rem", marginBottom: "0.5rem !important" }}
                >
                  <FiberManualRecordIcon sx={{ fontSize: "0.6rem", mr: 0.8 }} />
                  CIntrospect Profile gaps & help in <b>Career Transition</b>
                </Typography>
                <Typography
                  sx={{ fontSize: "0.8rem", marginBottom: "0.5rem !important" }}
                >
                  <FiberManualRecordIcon sx={{ fontSize: "0.6rem", mr: 0.8 }} />
                  <b>Analyze</b> Professional <b>Strengths & Weaknesses</b>
                </Typography>
                <Typography
                  sx={{ fontSize: "0.8rem", marginBottom: "0.5rem !important" }}
                >
                  <FiberManualRecordIcon sx={{ fontSize: "0.6rem", mr: 0.8 }} />
                  Personal <b>One-on-One</b> Interaction over <b>Resume</b>{" "}
                  improvement
                </Typography>
                <Typography
                  sx={{ fontSize: "0.8rem", marginBottom: "0.5rem !important" }}
                >
                  <FiberManualRecordIcon sx={{ fontSize: "0.6rem", mr: 0.8 }} />
                  Suggestions to optimize <b>LinkedIn</b>
                </Typography>
              </Box>
              <Box sx={{ mt: 7,px:{xs:2} }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    marginBottom: "0.5rem !important",
                  }}
                >
                  How will the Career Assistance session benefit me?
                </Typography>
                <Typography sx={{ fontSize: "1rem" }}>
                  It helps students to undergo self-analysis in identifying
                  their strengths and weaknesses, polishing skill gaps, and
                  overcoming any hesitation in transitioning to a new role.
                </Typography>
              </Box>
              <Box sx={{ mt: 7,px:{xs:2} }}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    marginBottom: "0.9rem !important",
                  }}
                >
                  FAQ
                </Typography>
                <Box>
                  <Accordion
                    sx={{
                      mb: 2,
                      width:{xs:"100%",lg:"90%"} ,
                      boxShadow: "0 2px 5px 0 rgb(0 0 0 / 25%)",
                      borderTopLeftRadius: "0.5rem!important",
                      borderTopRightRadius: "0.5rem!important",
                      borderBottomLeftRadius: "0.5rem",
                      borderBottomRightRadius: "0.5rem",
                    }}
                    expanded={expanded === "Faq1"}
                    onChange={handleChange("Faq1")}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "Faq1" ? (
                          <MinimizeIcon
                            sx={{ strokeWidth: 2, stroke: "#000" }}
                          />
                        ) : (
                          <AddIcon sx={{ strokeWidth: 2, stroke: "#000" }} />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="Faq1"
                      sx={{ height:{xs:80,lg:"20px"}  }}
                    >
                      <Typography
                        sx={{
                          py: 2,
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                          marginBottom: "0rem !important",
                          color: "#111",
                        }}
                      >
                        When should I opt for the Career Assistance session?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        borderTop: "1px solid ",
                        borderImage:
                          "linear-gradient(to right, transparent, #34495e, transparent)1",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.88rem",
                          marginBottom: "0rem !important",
                        }}
                      >
                        You can opt at any time during your program however it's
                        advised to do it at a stage where you have completed a
                        certain part of the program.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{
                      mb: 2,
                      width:{xs:"100%",lg:"90%"} ,
                      boxShadow: "0 2px 5px 0 rgb(0 0 0 / 25%)",
                      borderTopLeftRadius: "0.5rem!important",
                      borderTopRightRadius: "0.5rem!important",
                      borderBottomLeftRadius: "0.5rem",
                      borderBottomRightRadius: "0.5rem",
                    }}
                    expanded={expanded === "Faq2"}
                    onChange={handleChange("Faq2")}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "Faq2" ? (
                          <MinimizeIcon
                            sx={{ strokeWidth: 2, stroke: "#000" }}
                          />
                        ) : (
                          <AddIcon sx={{ strokeWidth: 2, stroke: "#000" }} />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="Faq2"
                      sx={{ height:{xs:80,lg:"20px"}  }}
                    >
                      <Typography
                        sx={{
                          py: 2,
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                          marginBottom: "0rem !important",
                          color: "#111",
                        }}
                      >
                        Are the sessions personalized and can I book a follow-up
                        session?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        borderTop: "1px solid ",
                        borderImage:
                          "linear-gradient(to right, transparent, #34495e, transparent)1",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.88rem",
                          marginBottom: "0rem !important",
                        }}
                      >
                        Yes, the sessions are personalized with the Coach and
                        you can book a follow-up session after you have
                        completed the shared tasks in the first session.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{
                      mb: 2,
                      width:{xs:"100%",lg:"90%"} ,
                      boxShadow: "0 2px 5px 0 rgb(0 0 0 / 25%)",
                      borderTopLeftRadius: "0.5rem!important",
                      borderTopRightRadius: "0.5rem!important",
                      borderBottomLeftRadius: "0.5rem",
                      borderBottomRightRadius: "0.5rem",
                    }}
                    expanded={expanded === "Faq3"}
                    onChange={handleChange("Faq3")}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "Faq3" ? (
                          <MinimizeIcon
                            sx={{ strokeWidth: 2, stroke: "#000" }}
                          />
                        ) : (
                          <AddIcon sx={{ strokeWidth: 2, stroke: "#000" }} />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="Faq3"
                      sx={{ height:{xs:80,lg:"20px"}  }}
                    >
                      <Typography
                        sx={{
                          py: 2,
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                          marginBottom: "0rem !important",
                          color: "#111",
                        }}
                      >
                        Do I get feedback on the Resume and advice on my
                        LinkedIn profile building?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        borderTop: "1px solid ",
                        borderImage:
                          "linear-gradient(to right, transparent, #34495e, transparent)1",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.88rem",
                          marginBottom: "0rem !important",
                        }}
                      >
                        The session is focused on sharing feedback for Resume
                        and LinkedIn profile building. It gives you clarity
                        about the gaps in your profile and what requires
                        immediate attention.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{
                      mb: 2,
                      width:{xs:"100%",lg:"90%"} ,
                      boxShadow: "0 2px 5px 0 rgb(0 0 0 / 25%)",
                      borderTopLeftRadius: "0.5rem!important",
                      borderTopRightRadius: "0.5rem!important",
                      borderBottomLeftRadius: "0.5rem",
                      borderBottomRightRadius: "0.5rem",
                    }}
                    expanded={expanded === "Faq4"}
                    onChange={handleChange("Faq4")}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "Faq4" ? (
                          <MinimizeIcon
                            sx={{ strokeWidth: 2, stroke: "#000" }}
                          />
                        ) : (
                          <AddIcon sx={{ strokeWidth: 2, stroke: "#000" }} />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="Faq4"
                      sx={{ height:{xs:80,lg:"20px"}  }}
                    >
                      <Typography
                        sx={{
                          py: 2,
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                          marginBottom: "0rem !important",
                          color: "#111",
                        }}
                      >
                        Is it helpful for mapping the Career Roadmap journey
                        ahead?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        borderTop: "1px solid ",
                        borderImage:
                          "linear-gradient(to right, transparent, #34495e, transparent)1",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.88rem",
                          marginBottom: "0rem !important",
                        }}
                      >
                        It is very helpful for building a Career framework that
                        will guide you towards building your dream career and
                        cracking the desired job role. A career roadmap enables
                        clarity of thinking and steps you can take for smooth
                        progression.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{
                      mb: 2,
                      width:{xs:"100%",lg:"90%"} ,
                      boxShadow: "0 2px 5px 0 rgb(0 0 0 / 25%)",
                      borderTopLeftRadius: "0.5rem!important",
                      borderTopRightRadius: "0.5rem!important",
                      borderBottomLeftRadius: "0.5rem",
                      borderBottomRightRadius: "0.5rem",
                    }}
                    expanded={expanded === "Faq5"}
                    onChange={handleChange("Faq5")}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "Faq5" ? (
                          <MinimizeIcon
                            sx={{ strokeWidth: 2, stroke: "#000" }}
                          />
                        ) : (
                          <AddIcon sx={{ strokeWidth: 2, stroke: "#000" }} />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="Faq5"
                      sx={{ height:{xs:80,lg:"20px"}  }}
                    >
                      <Typography
                        sx={{
                          py: 2,
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                          marginBottom: "0rem !important",
                          color: "#111",
                        }}
                      >
                        Will the Career Coach assist me with the Career
                        Transition?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        borderTop: "1px solid ",
                        borderImage:
                          "linear-gradient(to right, transparent, #34495e, transparent)1",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.88rem",
                          marginBottom: "0rem !important",
                        }}
                      >
                        The Career Coach will assist you with your
                        career-related queries and confusion. You can connect
                        and share fears and dilemmas related to career
                        transition. The session is friendly and aims to help you
                        in the self-evaluation process.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
      </>
  
    


            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default CareerCounselling;
