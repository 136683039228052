import React, { useState } from "react";
import programs from "../../../constants/programs.json"
import CallToAction from "./call-to-action";
import { MdArrowOutward } from "react-icons/md";
import BackdropLoader from "../../loaders/backdrop-loader";

const DashboardWithoutEnrollments = () => {

  const [isLoading, setIsLoading] = useState(false)

  return (
    <div className="w-full min-h-screen font-circular">
      <div className='w-full py-12 xl:px-12 px-4'>
        <div className='max-w-[85rem] w-full mx-auto'>
          <div className='w-full text-center'>
            <h1 className='text-3xl font-semibold capitalize'>Explore our <span className="text-universal">popular</span> courses!</h1>
          </div>
          <div className="flex gap-8 mt-8">
            <div className='flex-1 xl:flex-[0.7] grid grid-cols-1 md:grid-cols-2 gap-8'>
              {programs?.map((program, index) => (
                <div key={index} className='w-full rounded-2xl overflow-hidden bg-white drop-shadow-sm border flex flex-col relative'>
                  <div className="w-full pt-4 pb-2 px-4">
                    <div className='w-full flex items-center gap-4'>
                      <div className='w-10 h-10 flex-shrink-0 rounded-xl overflow-hidden p-2 bg-white drop-shadow-md'>
                        <img title={program.university_name} src={program.university_image} className='w-full h-full object-contain rounded-sm' alt={program.university_name} />
                      </div>
                      <h1 title={program.university_name} className='text-center font-medium text-xs text-neutral-500 cursor-context-menu'>
                        {program.university_name}
                      </h1>
                    </div>
                  </div>
                  <div className='w-full flex-1 flex flex-col justify-between px-4 pt-2 pb-4 cursor-context-menu'>
                    <h1 className='font-semibold'>{program.program_name}</h1>
                    <div className="w-full border-b border-neutral-300 mt-4" />
                    <div className='flex items-center justify-between w-full gap-2 mt-4'>
                      <h4 className='font-medium capitalize text-sm text-neutral-600'>{program.category}</h4>
                      <a target="_blank" rel="noreferrer" href={`https://accredian.com/programs/${program.id}`}>
                        <button className="px-4 py-2.5 text-xs font-semibold border rounded-lg text-center bg-universal flex gap-1 items-center text-white hover:bg-darkBlue transition-all duration-200 text-nowrap">
                          View Program
                          <MdArrowOutward />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex-[0.3] hidden xl:block">
              <CallToAction setIsLoading={setIsLoading}/>
            </div>
          </div>
        </div>
      </div>
      <BackdropLoader open={isLoading}/>
    </div>
  );
};
export default DashboardWithoutEnrollments;
