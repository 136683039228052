import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Badge,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  Backdrop,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LoginDashboard from "../Navbar/MobileSidebar";
import CircularProgress from "@mui/material/CircularProgress";
import Sidbar from "./DesktopSidebar";
import "./dashboard.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Moment from "react-moment";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Challengeoftheday from "./Challengeoftheday";
import axios from "axios";
import moment from "moment";
import { decode as atob, encode as btoa } from "base-64";
import ClearIcon from "@mui/icons-material/Clear";
import Swal from "sweetalert2";
import HowTo from "./Howto";
import AuthSidebar from "../../Auth/AuthSidebar";

const StudentDashboard = () => {
  let navigate = useNavigate();
  const dateToFormat = new Date();
  const [user, setUser] = useState("");
  const [classid, setClassid] = useState();
  const [Job, setJob] = useState("");
  const [insaidtv, setInsaidtv] = useState("");
  const [coures, setCoures] = useState(localStorage.getItem("courseDetails") ? JSON.parse(localStorage.getItem('courseDetails')) : "");
  const [today, setToday] = useState();
  const [mylink, setMylink] = useState();
  const [curtimeseconds, setCs] = useState();
  const [eventdate, setEd] = useState();
  const [starttimeseconds, setSts] = useState();
  const [endtimeseconds, setEts] = useState();
  const [isShown, setIsShown] = useState(false);
  const [insaidtvpop, setInsaidtvpop] = useState();
  const [info, setinfo] = useState(false);
  const [showcoins, setShowcoins] = useState(false);
  const [open, setOpen] = useState(false);
  const [openup, setOpenup] = useState(false);
  const [age, setAge] = useState("");
  const [term, setTerm] = useState("");
  const [baner, setBanerData] = useState("");
  const [loopen, setLoOpen] = useState(false);
  const [thinficurl, setThingicurl] = useState("");
  const LoaderOpen = () => {
    setLoOpen(true);
  };
  const LoaderClose = () => {
    setLoOpen(false);
  };
  const [friendname, setfriendname] = useState("");
  const [friendemail, setfriendemail] = useState("");
  const [friendnumber, setfriendnumber] = useState("");
  const [programname, setProgramname] = useState("");
  const [relation, setRelation] = useState("");
  const [mob, setmob] = useState(parseInt(localStorage.getItem("mobile")));
  const category = localStorage.getItem("category");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const newemail = btoa(localStorage.getItem("email"));
  const handleClickOpenref = async () => {
    setOpen(true);
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetchrefercount`,
      data: {
        category: localStorage.getItem("category"),
        user_id: localStorage.getItem("user_id"),
      },
    }).then((res) => {
      //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
    });
  };
  const handleChangename = (e) => {
    setfriendname(e.target.value);
  };
  const handleChangeemail = (e) => {
    setfriendemail(e.target.value);
  };
  const handleChangenumber = (e) => {
    setfriendnumber(e.target.value);
  };
  const handleChangeprogram = (e) => {
    setProgramname(e.target.value);
  }
  const handleChangerelation = (e) => {
    setRelation(e.target.value);
  }
  const handleCloseref = () => {
    setOpen(false);
  };
  const handleClickOpenup = async () => {
    setOpenup(true);
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetchProgramUpgradeCount`,
      data: {
        category: localStorage.getItem("category"),
        user_id: localStorage.getItem("user_id"),
      },
    }).then((res) => {
      //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
    });
  };

  const handleCloseup = () => {
    setOpenup(false);
  };
  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  const handleClose = () => {
    setInsaidtvpop(false);
  };
  const handleClickOpen = (indtv) => {
    setInsaidtvpop(true);
    setinfo(indtv);
  };
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  const sendData = {
    category: localStorage.getItem("category"),
  };
  const CourseData = {
    enrol_id: localStorage.getItem("enrol_id"),
  };

  const fetchbanner = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetchbanners`,
      data: {
        category: localStorage.getItem("category"),
        program_id: localStorage.getItem("program_id"),
      },
    }).then((res) => {
      setBanerData(res.data);

      //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
    });
  };
  const fetchthinficurl = async () => {
    LoaderOpen()
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetch_thinkific_url`,
      data: {
        email: localStorage.getItem("email"),
        firstname: localStorage.getItem("username"),
        lastname: localStorage.getItem("lastname"),
      },
    }).then((res) => {
      LoaderClose()
      setThingicurl(res.data);
      window.open(res.data, '_blank')

      //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
    });
  };

  const Startlearning = () => {
    fetchthinficurl();
  }
  useEffect(() => {
    LoaderOpen();
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    var term = localStorage.getItem("term");
    setTerm(term);
    setUser(user_id);
    fetchbanner();

    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetchaccrediantv`,
        JSON.stringify(sendData),
        options
      )
      .then((result) => {
        setInsaidtv(result.data);
      });
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetchhomejob`,
        JSON.stringify(sendData),
        options
      )
      .then((result) => {
        LoaderClose();
        setJob(result.data);
      });
    if (!localStorage.getItem("courseDetails")) {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetchcoursedetails`,
          JSON.stringify(CourseData),
          options
        )
        .then((result) => {
          localStorage.setItem("courseDetails", JSON.stringify(result.data))
          setCoures(result.data);
          LoaderClose();
        });
    }

    // axios
    //   .post(
    //     `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/dashboardhomepage/fetchliveclassdetails`,
    //     JSON.stringify(CourseData),
    //     options
    //   )
    //   .then((result) => {
    //     //console.log(result,"res")
    //     if (result.data.status == "300") {
    //       setClassid(1);
    //     } else {
    //       var newlink = btoa("id=" + result.data[0].id + "&user_id=" + user);
    //       setMylink(
    //         "https://www.accredian.com/sessionlink.php?token=" + newlink
    //       );
    //       setToday(moment().format("DD-MM-Y"));
    //       var curtime = moment().format("HH:mm:ss");
    //       var a = curtime.split(":");
    //       setCs(+a[0] * 60 * 60 + +a[1] * 60 + +a[2]);
    //       setEd(moment(result.data[0].start).format("DD-MM-Y"));
    //       var start_time = moment(result.data[0].start).format("HH:mm:ss");
    //       var b = start_time.split(":");
    //       setSts(+b[0] * 60 * 60 + +b[1] * 60 + +b[2]);
    //       var end_time = moment(result.data[0].end).format("HH:mm:ss");
    //       var c = end_time.split(":");
    //       setEts(+c[0] * 60 * 60 + +c[1] * 60 + +c[2]);
    //       //console.log(result.data[0].start);

    //       setInterval(() => {
    //         setEts(+c[0] * 60 * 60 + +c[1] * 60 + +c[2]);
    //         setSts(+b[0] * 60 * 60 + +b[1] * 60 + +b[2]);
    //       }, 600000);
    //     }
    //   });
  }, []);

  const sliderRef = useRef();
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };
  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    autoplay: true,
    autoplaySpeed: 6000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
        },
      },
    ],
  };
  // if (user == null) {
  //  window.location.href="https://accredian.com/Login"
  // }

  const handleChangemob = (e) => {
    if (e.target.value) {
      setmob(parseInt(e.target.value));
    }
    // else{
    //   setmob(parseInt(localStorage.getItem("mobile")))
    // }
  };

  const handleSubmit = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/iitgprogramUpgrade/programUpgradeRequest`,
      data: {
        mobile: mob,
        username: localStorage.getItem("username"),
        lastname: localStorage.getItem("lastname"),
        email: localStorage.getItem("email"),
      },
    }).then((res) => {
      if (res.data.status == 200) {
        handleCloseup();
        Swal.fire(
          "Success",
          "Your program upgrade request is submitted successfully. Our Academic manager will contact you soon.",
          "success"
        );
      }

      //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
    });
  };
  const handleSubmitRef = async (e) => {
    e.preventDefault();
    LoaderOpen();
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/referandEarn/refer`,
      data: {
        usermobile: localStorage.getItem("mobile"),
        enrol_id: localStorage.getItem("enrol_id"),
        firstname: localStorage.getItem("username"),
        lastname: localStorage.getItem("lastname"),
        useremail: localStorage.getItem("email"),
        batch_id: localStorage.getItem("batch_id"),
        friend_name: friendname,
        friend_email: friendemail,
        friend_mobile: friendnumber,
        userprogram: localStorage.getItem("program_name"),
        friend_program: programname,
        person: relation,
        category: localStorage.getItem("category")

      },
    }).then((res) => {
      if (res.data.status == 200) {
        handleCloseref();
        LoaderClose();
        Swal.fire(
          'success',
          'Thank you for registering for our referral program. Our counsellors will get in touch with the learner and update you on your reward.',
          'success'
        )
      }

      //  setfile(filesize(res.data[0].file_size, {base: 2, standard: "jedec"}));
    });
  }
  return (
    <>

      <LoginDashboard showcoins={showcoins} />
      <HowTo />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5, px: { xs: 2 } }}>
        <Box sx={{ pt: { lg: 8, xs: 2 } }}>
          <Grid container>
            <Grid item lg={3} sx={{ display: { xs: "none", lg: "block" } }}>
              <Sidbar />
              {/* <AuthSidebar/> */}
            </Grid>
            <Grid item lg={9} xs={12}>
              <Box sx={{ mr: { lg: 7 }, pb: baner.status == 404 ? { lg: 0, xs: 5, md: 0, sm: 0 } : { lg: 15, xs: 5, md: 15, sm: 15 } }}>
                {(() => {
                  if (
                    baner.referral_status === 1 &&
                    baner.upgrade_status === 1
                  ) {
                    return (
                      <>
                        <Slider ref={sliderRef} {...settings}>
                          <CardMedia
                            component="img"
                            image={`https://dash.accredian.com/${baner.refferal_image}`}
                            onClick={handleClickOpenref}
                          />
                          <CardMedia
                            component="img"
                            image={`https://dash.accredian.com/${baner.upload_image}`}
                            onClick={handleClickOpenup}
                          />
                        </Slider>

                        <Box
                          sx={{
                            mt: { lg: -12, xs: -6, sm: -8, md: -12 },
                            ml: { lg: -2, xs: -1 },
                          }}
                        >
                          {" "}
                          <ArrowBackIosIcon
                            onClick={() => gotoPrev()}
                            sx={{ fontSize: { xs: 12, lg: 17, xl: 17 } }}
                          ></ArrowBackIosIcon>
                        </Box>

                        <Box sx={{ mt: { lg: -3.5, xs: -3, sm: -4, md: -5 } }}>
                          <ArrowForwardIosIcon
                            onClick={() => gotoNext()}
                            sx={{
                              ml: { xl: "1255px", xs: "100%" },
                              fontSize: { xs: 12, lg: 17, xl: 17 },
                            }}
                          ></ArrowForwardIosIcon>
                        </Box>
                      </>
                    );
                  } else if (baner.referral_status === 1) {
                    return (
                      <>
                        <CardMedia
                          component="img"
                          image={`https://dash.accredian.com/${baner.refferal_image}`}
                          onClick={handleClickOpenref}
                        />
                        <Box
                          sx={{
                            mt: { lg: -12, xs: -6 },
                            ml: { lg: -3, xs: -1 },
                          }}
                        >
                          {" "}
                          {/* <ArrowBackIosIcon
                            onClick={() => gotoPrev()}
                            sx={{
                              fontSize: { xs: 12, lg: 17 },
                              color: "#f3f6f9",
                            }}
                          ></ArrowBackIosIcon> */}
                        </Box>

                        {/* <Box sx={{ mt: { lg: -3.5, xs: -3 } }}>
                          <ArrowForwardIosIcon
                            onClick={() => gotoNext()}
                            sx={{
                              ml: {xl:117,lg: 117, xs: "100%" },
                              fontSize: { xs: 12, lg: 17 },
                              color: "#f3f6f9",
                            }}
                          ></ArrowForwardIosIcon>
                        </Box> */}
                      </>
                    );
                  } else if (baner.upgrade_status === 1) {
                    return (
                      <>
                        <CardMedia
                          component="img"
                          image={`https://dash.accredian.com/${baner.upload_image}`}
                          onClick={handleClickOpenup}
                        />
                        <Box
                          sx={{
                            mt: { lg: -12, xs: -6 },
                            ml: { lg: -3, xs: -1 },
                          }}
                        >
                          {" "}
                          {/* <ArrowBackIosIcon
                            onClick={() => gotoPrev()}
                            sx={{
                              fontSize: { xs: 12, lg: 17 },
                              color: "#f3f6f9",
                            }}
                          ></ArrowBackIosIcon> */}
                        </Box>

                        {/* <Box sx={{ mt: { lg: -3.5, xs: -3 } }}>
                          <ArrowForwardIosIcon
                            onClick={() => gotoNext()}
                            sx={{
                              ml: {xl:117, lg: 117, xs: "100%" },
                              fontSize: { xs: 12, lg: 17 },
                              color: "#f3f6f9",
                            }}
                          ></ArrowForwardIosIcon>
                        </Box> */}
                      </>
                    );
                  }
                  // else if (localStorage.getItem("category") == 4 || localStorage.getItem("category") == 9) {
                  //   return (
                  //     <>
                  //      <a href="https://us06web.zoom.us/j/89218019478?pwd=MbQaZyIHkqClubRlSqCSUTKUEW2U3X.1"
                  //         target="_blank"><CardMedia
                  //         component="img"
                  //         image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/pmsession.webp"

                  //         sx={{mb:5}}
                  //       />
                  //       </a> 



                  //     </>
                  //   );
                  // }
                })()}
              </Box>
              <Grid container sx={{ mb: 4 }}>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  sm={4}
                  md={4}
                  xs={12}
                  sx={{
                    maxWidth: { lg: "31.333333% !important" },
                    mb: { xs: 2 },
                  }}
                >
                  <Paper
                    sx={{ pt: 3, pb: 1, borderRadius: "16px" }}
                    elevation={3}
                  >
                    <CardMedia
                      component="img"
                      image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/1.webp"
                      alt="Goole"
                      sx={{
                        height: "126px",
                        width: "190px",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <Box sx={{ mx: 2, mt: 2 }}>
                      <Button
                        variant="contained"
                        sx={{
                          mb: 2,
                          background: "#002D60",
                          boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                          borderRadius: "8px",
                          textTransform: "none",
                          width: "100%",
                          height: "32px",
                        }}
                        className="start-learning-button"
                        // href={thinficurl}
                        onClick={Startlearning}
                      // target="_blank"
                      >
                        My Courses{" "}
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  sm={4}
                  md={4}
                  xs={12}
                  sx={{
                    maxWidth: { lg: "31.333333% !important" },
                    ml: { lg: 1 },
                    mb: { xs: 2 },
                  }}
                >
                  <Paper
                    sx={{ pt: 3, pb: 1, borderRadius: "16px" }}
                    elevation={3}
                  >
                    <CardMedia
                      component="img"
                      image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/2.webp"
                      alt="Goole"
                      sx={{
                        height: "126px",
                        width: "190px",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    {(() => {
                      if (
                        today == eventdate &&
                        curtimeseconds > starttimeseconds - 60 * 30 &&
                        curtimeseconds < endtimeseconds + 60 * 30
                      ) {
                        return (
                          <>
                            <Button
                              sx={{
                                background: "red",
                                borderRadius: "10px",
                                textTransform: "none",
                                fontSize: "0.6rem",
                                float: "right",
                                mt: -18,
                                mr: 2,
                                color: "#fff",
                                p: 0.2,
                                width: "80px",
                                "&:hover": {
                                  background: "red",
                                  borderRadius: "10px",
                                  textTransform: "none",
                                  fontSize: "0.6rem",
                                  color: "#fff",
                                  p: 0.2,
                                  width: "80px",
                                },
                              }}
                              className="blink"
                              href={mylink}
                              target="_blank"
                            >
                              <FiberManualRecordIcon
                                sx={{ fontSize: "1rem", mr: 0.5 }}
                              />
                              Live Class{" "}
                            </Button>
                          </>
                        );
                      } else if (classid == 1) {
                        return (
                          <>
                            <Button
                              sx={{ float: "right", mt: -18, mr: 2 }}
                            ></Button>
                          </>
                        );
                      }
                    })()}
                    <Box sx={{ mx: 2, mt: 2 }}>
                      <Button
                        variant="contained"
                        sx={{
                          mb: 2,
                          background: "#002D60",
                          boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                          borderRadius: "8px",
                          textTransform: "none",
                          width: "100%",
                          height: "32px",
                          "&:hover": {
                            background: "#002D60",
                            boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                            borderRadius: "8px",
                            textTransform: "none",
                            width: "100%",
                            height: "32px",
                          },

                        }}
                        href={`https://calendar.accredian.com/${newemail}`}
                        target="_blanck"
                      >
                        Event calendar{" "}
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  sm={4}
                  md={4}
                  xs={12}
                  sx={{
                    maxWidth: { lg: "31.333333% !important" },
                    ml: { lg: 1 },
                    mb: { xs: 2 },
                  }}
                >
                  {(() => {
                    if (term > 3 || category == "9" || category == "13") {
                      return (
                        <>
                          <Paper
                            sx={{ pt: 3, pb: 1, borderRadius: "16px" }}
                            elevation={3}
                          >
                            <CardMedia
                              component="img"
                              image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/3.webp"
                              alt="Goole"
                              sx={{
                                height: "126px",
                                width: "190px",
                                display: "block",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            />
                            <Box sx={{ mx: 2, mt: 2 }}>
                              <Link to="/CareerCounselling">
                                {" "}
                                <Button
                                  variant="contained"
                                  sx={{
                                    mb: 2,
                                    background: "#002D60",
                                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                    borderRadius: "8px",
                                    textTransform: "none",
                                    width: "100%",
                                    height: "32px",
                                    "&:hover": {
                                      background: "#002D60",
                                      boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                      borderRadius: "8px",
                                      textTransform: "none",
                                      width: "100%",
                                      height: "32px",
                                    },
                                  }}
                                >
                                  Career Counseling{" "}
                                </Button>
                              </Link>
                            </Box>
                          </Paper>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <Box
                            sx={{
                              background: "#D9D9D9CC",
                              borderRadius: "16px",
                              opacity: 1,
                              height: 224,
                            }}
                          >
                            <Paper
                              sx={{
                                pt: 3,
                                pb: 1,
                                borderRadius: "16px",
                                opacity: 0.2,
                              }}
                              elevation={3}
                            >
                              <CardMedia
                                component="img"
                                image="https://storage.googleapis.com/accredian-assets/Frontend_Assests/Images/Accredian-react-site-images/Student-Dashboard/3.webp"
                                alt="Goole"
                                sx={{
                                  height: "126px",
                                  width: "190px",
                                  display: "block",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              />
                              <Box sx={{ mx: 2, mt: 2 }}>
                                <Button
                                  variant="contained"
                                  sx={{
                                    mb: 2,
                                    background: "#002D60",
                                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                    borderRadius: "8px",
                                    textTransform: "none",
                                    width: "100%",
                                    height: "32px",
                                    "&:hover": {
                                      background: "#002D60",
                                      boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                      borderRadius: "8px",
                                      textTransform: "none",
                                      width: "100%",
                                      height: "32px",
                                    },
                                  }}
                                >
                                  Career Counseling{" "}
                                </Button>
                              </Box>
                            </Paper>
                            <center>
                              {" "}
                              <Button
                                variant="contained"
                                sx={{
                                  mt: -32,
                                  background: "#35709D",
                                  boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                  borderRadius: "8px",
                                  textTransform: "none",
                                  width: "40%",

                                  "&:hover": {
                                    background: "#35709D",
                                    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                    borderRadius: "8px",
                                    textTransform: "none",
                                    width: "40%",
                                  },
                                }}
                              >
                                Unlock{" "}
                              </Button>
                            </center>
                          </Box>
                        </>
                      );
                    }
                  })()}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={7.5} xs={12} sm={12} md={7}>
                  <Challengeoftheday setShowcoins={setShowcoins} />
                </Grid>
                <Grid item lg={4} xs={12} sm={12} md={5} sx={{ ml: { lg: 2 }, px: { md: 2 } }}>
                  <Paper
                    sx={{
                      py: 3,
                      borderRadius: "15px",
                    }}
                  >
                    <Box display="flex" sx={{ mb: 1, px: 2 }}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: { lg: "1.1rem", sm: "1.3rem", md: "1.1rem", xs: "1.1rem" },
                          marginBottom: "0rem !important",
                        }}
                      >
                        Course:
                      </Typography>
                      <Typography
                        sx={{
                          background: "#046582",
                          color: "#fff",
                          fontWeight: 600,
                          width: { lg: "25%", sm: "35%", md: "25%", xs: "25%" },
                          pl: 1.2,
                          fontSize: { lg: "1.1rem", sm: "1.3rem", md: "1.1rem", xs: "1.1rem" },
                          borderRadius: 1,
                          height: { lg: "24px", sm: "34px", md: "24px", xs: "24px" },
                          ml: 2,
                          mt: 0.5,
                          paddingTop: "1px",
                          marginBottom: "0.4rem !important",
                          width: "117px",
                        }}
                      >
                        {coures.program_code}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        px: 2,
                        height: { lg: "410px", sm: "410px", md: "490px", xs: "410px" },
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                          width: "0.2em",
                        },
                        "&::-webkit-scrollbar-track": {
                          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "rgba(0,0,0,0)",
                          outline: "1px solid #fff",
                        },
                      }}
                    >
                      <Timeline
                        sx={{
                          [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            marginRight: "-40px",
                          },
                        }}
                      >
                        {coures.terms &&
                          coures.terms.map((cd, index) => (
                            <>
                              {(() => {
                                if (
                                  localStorage.getItem("program_id") == "11" || localStorage.getItem("program_id") == "12" || localStorage.getItem("program_id") == "13" || localStorage.getItem("program_id") == "19"
                                ) {
                                  if (
                                    cd.term_id == "7" || cd.term_id == "8"
                                  ) {
                                    return (
                                      <>

                                      </>
                                    );
                                  }
                                  else {
                                    return (
                                      <>
                                        <TimelineItem
                                          sx={{ minHeight: "16px" }}
                                          key={cd.term_id}
                                        >
                                          <>

                                            <TimelineSeparator>
                                              {(() => {
                                                if (coures.active_term > cd.term_id) {
                                                  return (
                                                    <>
                                                      <TimelineDot
                                                        sx={{
                                                          margin: "7.5px 0",
                                                          padding: "4px",
                                                          backgroundColor: "#50cd89",
                                                        }}
                                                      />
                                                    </>
                                                  );
                                                } else if (
                                                  coures.active_term < cd.term_id
                                                ) {
                                                  return (
                                                    <>
                                                      <TimelineDot
                                                        sx={{
                                                          margin: "7.5px 0",
                                                          padding: "4px",
                                                        }}
                                                        variant="outlined"
                                                      />
                                                    </>
                                                  );
                                                }
                                                else if (
                                                  localStorage.getItem("program_id") == "11"
                                                ) {
                                                  return (
                                                    <>
                                                      <TimelineDot
                                                        sx={{
                                                          margin: "7.5px 0",
                                                          padding: "4px",
                                                        }}
                                                        variant="outlined"
                                                      />
                                                    </>
                                                  );
                                                }
                                                else if (
                                                  coures.active_term == cd.term_id
                                                ) {
                                                  return (
                                                    <>
                                                      <TimelineDot
                                                        sx={{
                                                          margin: "7.5px 0",
                                                          padding: "4px",
                                                          borderColor: "#50cd89",
                                                        }}
                                                        variant="outlined"
                                                      />
                                                    </>
                                                  );
                                                }
                                              })()}

                                              <TimelineConnector
                                                sx={{ height: "10px!important" }}
                                              />
                                            </TimelineSeparator>
                                            <TimelineContent
                                              sx={{
                                                fontSize: "0.7rem",
                                                width: "250px!important",
                                              }}
                                            >
                                              {cd.term_name}
                                            </TimelineContent>
                                          </>
                                        </TimelineItem>
                                      </>
                                    );

                                  }
                                }
                                else {
                                  return (
                                    <>
                                      <TimelineItem
                                        sx={{ minHeight: "16px" }}
                                        key={cd.term_id}
                                      >
                                        <>

                                          <TimelineSeparator>
                                            {(() => {
                                              if (coures.active_term > cd.term_id) {
                                                return (
                                                  <>
                                                    <TimelineDot
                                                      sx={{
                                                        margin: "7.5px 0",
                                                        padding: "4px",
                                                        backgroundColor: "#50cd89",
                                                      }}
                                                    />
                                                  </>
                                                );
                                              } else if (
                                                coures.active_term < cd.term_id
                                              ) {
                                                return (
                                                  <>
                                                    <TimelineDot
                                                      sx={{
                                                        margin: "7.5px 0",
                                                        padding: "4px",
                                                      }}
                                                      variant="outlined"
                                                    />
                                                  </>
                                                );
                                              }
                                              else if (
                                                localStorage.getItem("program_id") == "11"
                                              ) {
                                                return (
                                                  <>
                                                    <TimelineDot
                                                      sx={{
                                                        margin: "7.5px 0",
                                                        padding: "4px",
                                                      }}
                                                      variant="outlined"
                                                    />
                                                  </>
                                                );
                                              }
                                              else if (
                                                coures.active_term == cd.term_id
                                              ) {
                                                return (
                                                  <>
                                                    <TimelineDot
                                                      sx={{
                                                        margin: "7.5px 0",
                                                        padding: "4px",
                                                        borderColor: "#50cd89",
                                                      }}
                                                      variant="outlined"
                                                    />
                                                  </>
                                                );
                                              }
                                            })()}

                                            <TimelineConnector
                                              sx={{ height: "10px!important" }}
                                            />
                                          </TimelineSeparator>
                                          <TimelineContent
                                            sx={{
                                              fontSize: "0.7rem",
                                              width: "250px!important",
                                            }}
                                          >
                                            {cd.term_name}
                                          </TimelineContent>
                                        </>
                                      </TimelineItem>
                                    </>
                                  );
                                }
                              })()}

                            </>
                          ))}
                      </Timeline>

                    </Box>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 3 }}>
                <Grid item lg={7.5} sm={12} md={7} xs={12} sx={{ mb: { xs: 2 } }}>
                  <Paper
                    sx={{
                      py: 3,
                      borderRadius: "15px",
                    }}
                  >
                    <Box display="flex" sx={{ mb: 3, px: 2 }}>
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "1.1rem",
                          marginBottom: "0rem !important",
                        }}
                      >
                        Job Portal
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        height: "420px",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                          width: "0.2em",
                        },
                        "&::-webkit-scrollbar-track": {
                          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "rgba(0,0,0,0)",
                          outline: "1px solid #fff",
                        },
                      }}
                    >
                      {Array.isArray(Job) &&
                        Job?.map((jb) => (
                          <a
                            href={jb.job_link}
                            target="_blank"
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            <Box
                              sx={{
                                boxShadow:
                                  "rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px",
                                mx: 2,
                                p: 2,
                                borderRadius: "5px",
                                mb: 1,
                              }}
                              key={jb.id}
                            >
                              <Grid container>
                                <Grid item lg={4} sm={4} md={4} xs={4}>
                                  <Typography
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: "0.8rem",
                                      marginBottom: "0rem !important",
                                      width: { lg: 220, xs: 80, sm: 150 },
                                    }}
                                  >
                                    {jb.job_title}
                                  </Typography>
                                </Grid>
                                <Grid item lg={4} sm={4} md={4} xs={4}>
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "0.9rem",
                                      marginBottom: "0rem !important",
                                      color: "#055FA0",
                                      textAlign: "center",
                                      ml: { xs: 1 },
                                      width: { lg: 220, xs: 80, sm: 150 },
                                    }}
                                  >
                                    {jb.company_name}
                                  </Typography>
                                </Grid>
                                <Grid item lg={4} sm={4} md={4} xs={4}>
                                  <Typography
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: "0.8rem",
                                      marginBottom: "0rem !important",
                                      textAlign: "center",
                                      ml: { xs: 1 },
                                      width: { lg: 220, xs: 80, sm: 150 },
                                    }}
                                  >
                                    {jb.level} level
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </a>
                        ))}
                      <Box sx={{ py: 2 }}>
                        <center>
                          <Link
                            to="/Job-Protal"
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                background: "#009ef7",
                                boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                borderRadius: "50px",
                                textTransform: "none",
                                height: "35px",

                                mt: -1,
                                "&:hover": {
                                  background: "#009ef7",
                                  boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
                                  borderRadius: "50px",
                                  textTransform: "none",
                                  height: "35px",
                                },
                              }}
                            >
                              View more jobs{" "}
                            </Button>
                          </Link>
                        </center>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item lg={4} sm={12} md={5} xs={12} sx={{ ml: { lg: 2 }, px: { md: 2 } }}>
                  <Paper
                    sx={{
                      py: 3,
                      borderRadius: "15px",
                    }}
                  >
                    <Box display="flex" sx={{ mb: 1, px: 2 }}>
                      <LiveTvIcon />
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "1.1rem",
                          marginBottom: "0rem !important",
                          ml: 2,
                        }}
                      >
                        Accredian TV
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        px: 2,
                        pt: 2,
                        height: "435px",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                          width: "0.2em",
                        },
                        "&::-webkit-scrollbar-track": {
                          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "rgba(0,0,0,0)",
                          outline: "1px solid #fff",
                        },
                      }}
                    >
                      {insaidtv &&
                        insaidtv.map((indtv) => (
                          <Box
                            className="containers"
                            onClick={() => handleClickOpen(indtv)}
                          >
                            <CardMedia
                              component="img"
                              image={indtv.thumbnails}
                              alt="Goole"
                              sx={{
                                mb: 4,
                                width: { lg: "270px !important ", sm: "280px", md: "340px", xs: "320px" },
                                height: "160px",
                              }}
                              className="neimage"
                            />
                            <Box className="cr">
                              <Typography
                                sx={{
                                  mt: -13,
                                  width: "250px",
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  fontSize: "0.85rem",
                                }}
                              >
                                {indtv.title}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Dialog
        open={insaidtvpop}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{}}
      >
        <DialogTitle id="shreefeddbackopen" sx={{ width: "580px" }}>
          {info.title}
          <ClearIcon
            onClick={handleClose}
            sx={{ float: "right", mt: 0.5, ml: 2, cursor: "pointer" }}
          />
        </DialogTitle>
        <DialogContent>
          <iframe
            width="100%"
            height="315"
            frameborder="0"
            allowfullscreen="1"
            allow="autoplay; encrypted-media;"
            src={
              `https://www.youtube-nocookie.com/embed/` +
              info.videoId +
              `?autoplay=1&cc_load_policy=1rel=0&showinfo=0`
            }
          ></iframe>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleCloseref}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ p: 3 }}
      >
        <DialogTitle
          sx={{
            width: { lg: "430px", sm: "430px", md: "430px" },
            fontSize: 15,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Refer your friends here
          <ClearIcon
            onClick={handleCloseref}
            sx={{ float: "right", mt: 0.5, ml: 2, cursor: "pointer" }}
          />
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitRef}>
            <Box sx={{ mb: 2, mt: 2 }}>
              <TextField
                onChange={handleChangename}
                fullWidth
                label="Your friend's name"
                required={true}
                name="name"
                id="name"
                type="text"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                onChange={handleChangeemail}
                fullWidth
                label="Your friend's email"
                required={true}
                name="email"
                id="email"
                type="email"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                onChange={handleChangenumber}
                fullWidth
                label="Your friend's mobile number"
                required={true}
                name="number"
                id="number"
                type="number"

              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                id="program"
                select
                name="program"
                onChange={handleChangeprogram}
                fullWidth
                label={programname === "" ? "Select Program" : ""}
                // label="Select Program"
                required={true}
                InputLabelProps={{ shrink: false }}
              // defaultValue="Select a program"
              >
                {/* <MenuItem value="Select a program">
                <em>--- Select a program ---</em>
              </MenuItem> */}
                <MenuItem value="IIMV - PG Certificate Program in Strategic Product Management">
                  IIMV - PG Certificate Program in Strategic Product Management
                </MenuItem>
                <MenuItem value="IIMV - Executive Program in Business Management">
                  IIMV - Executive Program in Business Management
                </MenuItem>
                <MenuItem value="IIMV - Senior Management Program">
                  IIMV - Senior Management Program
                </MenuItem>
                <MenuItem value="IIMV - Executive Program in Digital Leadership for Business Transformation">
                  SPJAIN - Executive Program in Digital Leadership for Business Transformation
                </MenuItem>
                <MenuItem value="XLRI - Executive Program in Strategy & Leadership">
                  XLRI - Executive Program in Strategy & Leadership
                </MenuItem>
                <MenuItem value="XLRI - Executive Program in General Management">
                  XLRI - Executive Program in General Management
                </MenuItem>
                {/* <MenuItem value="IITG - Executive Program in Product Management and Business Analytics">
              IITG - Executive Program in Product Management and Business Analytics
              </MenuItem>
              <MenuItem value="IITG - Executive Program in Product Management and Digital Transformation">
              IITG - Executive Program in Product Management and Digital Transformation
              </MenuItem>
              <MenuItem value="IITG - Executive Program in Product Management">
              IITG - Executive Program in Product Management
              </MenuItem>
              <MenuItem value="IITG - Advanced Certification in Product Management">
              IITG - Advanced Certification in Product Management
              </MenuItem>
              <MenuItem>
                <em>--- Product Management Programs---</em>
              </MenuItem>
              <MenuItem value="Certificate in Product Management Foundation">
                Certificate in Product Management Foundation | 3 months
              </MenuItem>
              <MenuItem value="Global Certificate in Product Management">
                Global Certificate in Product Management | 6 months
              </MenuItem>
              <MenuItem value="PGP in Product Management">
                PGP in Product Management | 10 months
              </MenuItem>
              <MenuItem value="PGP in Product Management and Data Science">
                PGP in Product Management and Data Science | 12 months
              </MenuItem>
              <MenuItem value="PGP in Product Management">
                PGP in Product Management | 12 months
              </MenuItem> */}
              </TextField>
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                id="relation"
                select
                name="relation"

                onChange={handleChangerelation}
                fullWidth

                label={relation === "" ? "how do you know this person?" : ""}
                // label="Select Program"
                required={true}
                InputLabelProps={{ shrink: false }}
              // defaultValue="Select Relation"
              >
                {/* <MenuItem value="Select Relation">
                <em>--- Select Relation ---</em>
              </MenuItem> */}
                <MenuItem value="Friend">Friend</MenuItem>
                <MenuItem value="Colleague">Colleague</MenuItem>
                <MenuItem value="Manager">Manager</MenuItem>
                <MenuItem value="Relative">Relative</MenuItem>
              </TextField>
            </Box>
            <Box display="grid" justifyContent="center">
              <Button
                variant="contained"
                type="submit"

                sx={{
                  background: "#DE8D2D",
                  borderRadius: "5px",
                  textTransform: "none",
                  "&:hover": {
                    background: "#DE8D2D",

                    borderRadius: "5px",
                    textTransform: "none",
                  },
                }}
              >
                Refer Now
              </Button>
            </Box>
          </form>
        </DialogContent>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loopen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>

      <Dialog
        open={openup}
        onClose={handleCloseup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ p: 3 }}
      >
        <DialogTitle sx={{ width: { lg: "430px", sm: "430px", md: "430px" }, fontSize: 15, fontWeight: "bold" }}>
          The following benefits come with a brand new IIT Guwahati 6/12 Months
          Program Upgrade:
          <ClearIcon
            onClick={handleCloseup}
            sx={{ float: "right", ml: 4, mt: -3, cursor: "pointer" }}
          />
        </DialogTitle>
        <DialogContent>
          <Box mb={3}>
            <Typography
              sx={{ fontSize: 14, marginBottom: "0.2rem !important" }}
            >
              <FiberManualRecordIcon sx={{ fontSize: 8, mx: 1 }} />
              IIT Certification
            </Typography>
            <Typography
              sx={{ fontSize: 14, marginBottom: "0.2rem !important" }}
            >
              <FiberManualRecordIcon sx={{ fontSize: 8, mx: 1 }} />
              IIT Campus Immersion
            </Typography>
            <Typography
              sx={{ fontSize: 14, marginBottom: "0.2rem !important" }}
            >
              <FiberManualRecordIcon sx={{ fontSize: 8, mx: 1 }} />
              Master Classes from IIT Faculties
            </Typography>
            <Typography
              sx={{ fontSize: 14, marginBottom: "0.2rem !important" }}
            >
              <FiberManualRecordIcon sx={{ fontSize: 8, mx: 1 }} />
              Huge Scholarship
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              onChange={handleChangemob}
              fullWidth
              defaultValue={localStorage.getItem("mobile")}
            />
          </Box>

          <Box display="grid" justifyContent="center">
            <Button
              variant="contained"
              type="submit"
              onClick={handleSubmit}
              sx={{
                background: "#DE8D2D",
                borderRadius: "5px",
                textTransform: "none",

                "&:hover": {
                  background: "#DE8D2D",

                  borderRadius: "5px",
                  textTransform: "none",
                },
              }}
            >
              Request a callback
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
export default StudentDashboard;
// export default StudentDashboard;
