import { useEffect, useState } from "react";
import sidebarData from "../../../Assets/constants/sidebar.json"
import { Link, useLocation } from "react-router-dom";
import DropDown from "./DropDown/DropDown";
import { MdOutlineLockClock } from "react-icons/md";
import PID_MAP from "../../../Assets/constants/inclusion_pid.json"
import { MdFeedback } from "react-icons/md";

import "./Sidebar.css"


const Sidebar = ({ setOpen }) => {

  // eslint-disable-next-line no-unused-vars
  const [category, setCategory] = useState("other");
  const [cols, setCols] = useState([]);

  useEffect(() => {
    let selectedCategory = localStorage.getItem("category") || "other";
    setCategory(selectedCategory);

    let filteredData = sidebarData.filter(col => col.categories.includes(selectedCategory));
    setCols(filteredData);

    if (filteredData.length === 0) {
      selectedCategory = "other";
      setCategory(selectedCategory);
      filteredData = sidebarData.filter(col => col.categories.includes(selectedCategory));
      setCols(filteredData);
    }
  }, []);

  const { pathname } = useLocation()

  const email = btoa(localStorage.getItem("email"));

  const program_inclusion_pid = localStorage.getItem("program_id");
  const program_inclusive_id = PID_MAP[program_inclusion_pid];

  return (
    <div className="sidebar-main w-[250px] mx-6 sticky top-[15%]">
      <div className="w-full px-2 py-4 rounded-xl bg-white drop-shadow-xl">
        <div className="w-full">
          <h1 className="text-sm font-semibold text-neutral-500">Dashboard</h1>
          <div className="w-full flex flex-col gap-2 px-2 py-2 max-h-[60vh] overflow-y-auto custom-scrollbar">
            {cols?.map((col, index) => (
              <div key={index} className="w-full">
                {col.type === "link" ? (
                  <Link to={col.link}>
                    <div className={`py-2 px-2 rounded flex items-center gap-2 hover:bg-[#E6F2FF] hover:text-black transition-all duration-150 ${pathname === col.link ? "bg-[#E6F2FF] text-black" : "text-[#7e8299]"}`}>
                      <div className="w-6 h-6 flex-shrink-0">
                        <img className="w-full h-full object-contain" src={pathname === col.link ? col?.active : col?.icon} alt={col.title} />
                      </div>
                      <h1 className="text-sm font-semibold w-full h-full">{col.title}</h1>
                    </div>
                  </Link>
                ) : col.type === "external" ? (
                  <a target="_blank" rel="noreferrer" href={`${col.link}/${email}`}>
                    <div className="flex items-center gap-2 py-2 px-2 bg-white  text-neutral-500 hover:bg-[#E6F2FF] hover:text-black transition-all duration-150 rounded">
                      <div className="w-6 h-6 flex-shrink-0">
                        <img className="w-full h-full object-contain" src={col?.icon} alt={col.title} />
                      </div>
                      <h1 className="text-sm font-semibold w-full h-full">{col.title}</h1>
                    </div>
                  </a>
                ) : col.type === "inclusion" ? (
                  <a target="_blank" rel="noreferrer" href={`${col.link}/${program_inclusive_id}`}>
                    <div className="flex items-center gap-2 py-2 px-2 bg-white  text-neutral-500 hover:bg-[#E6F2FF] hover:text-black transition-all duration-150 rounded">
                      <div className="w-6 h-6 flex-shrink-0">
                        <img className="w-full h-full object-contain" src={col?.icon} alt={col.title} />
                      </div>
                      <h1 className="text-sm font-semibold w-full h-full">{col.title}</h1>
                    </div>
                  </a>
                ) : col.type === "dropdown" ? (
                  <DropDown pathname={pathname} col={col} />
                ) : col.type === "modal" ? (
                  <button onClick={() => setOpen("share-feedback")} className="text-sm w-full text-start">
                    <div className="flex items-center gap-2 py-2 px-2 bg-white rounded hover:bg-[#E6F2FF] hover:text-black transition-all duration-150  text-neutral-500">
                      <div className="w-6 h-6 flex-shrink-0">
                        <img className="w-full h-full object-contain" src={col?.icon} alt={col.title} />
                      </div>
                      <h1 className="text-sm font-semibold w-full h-full">{col.title}</h1>
                    </div>
                  </button>
                ) : (
                  <button className="text-sm w-full text-start" disabled>
                    <div className="flex items-center gap-2 justify-between py-2 px-2 bg-white rounded  text-neutral-500 hover:bg-[#E6F2FF] hover:text-black transition-all duration-150">
                      <div className="flex gap-2 items-center">
                        <div className="w-6 h-6 flex-shrink-0">
                          <img className="w-full h-full object-contain" src={col?.icon} alt={col.title} />
                        </div>
                        <h1 className="text-sm font-semibold w-full h-full">{col.title}</h1>
                      </div>
                      <MdOutlineLockClock size={20} className="cursor-pointer" title="Coming Soon" />
                    </div>
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-end">
        <button onClick={() => setOpen("feedback")} className="px-4 py-2 flex gap-2 items-center font-semibold capitalize text-neutral-500">
          <MdFeedback className="text-red-400" size={20} />
          feedback
        </button>
      </div>
    </div>
  )
}

export default Sidebar