import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  Backdrop,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Dashborednav from "../Navbar/Dashborednav";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Dashboard from "../Dashboard/Dashboard";
import Swal from "sweetalert2";
import { MdArrowOutward } from "react-icons/md";
import { ProgramCard } from "./programs/program-card";
const Myaccount = () => {
  const [user, setUser] = useState("");
  const [enrooll, setEnrooll] = useState("");
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();
  const [program, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const programcount = localStorage.getItem("count");

  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  // let navigate = useNavigate();
  useEffect(() => {
    const fetchToken = async () => {
      // console.log("called")
      const token = document.cookie.replace(
        /(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
      if (token) {
        const options = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            authorization: token,
          },
        };
        await axios
          .post(
            `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/verifyToken/token`,
            options
            // { withCredentials: true }
          )
          .then((result) => {
            if (result.data.status == 200) {
              const user_data = result.data.data;
              if (user_data.count == "2" || user_data.count == "3") {
                console.log(user_data.count, "enrol count");
                window.localStorage.setItem("user_id", user_data.user_id);
                window.localStorage.setItem("count", user_data.count);
                window.localStorage.setItem("username", user_data.firstname);
                window.localStorage.setItem(
                  "lastname",
                  user_data.userlastname
                );
                window.localStorage.setItem("email", user_data.email);
                window.localStorage.setItem("mobile", user_data.mobile);
                window.localStorage.setItem("dob", user_data.dob);
                window.localStorage.setItem("batch_id", user_data.batch_id);
                window.localStorage.setItem("term", user_data.term);
                // navigate(`/`);
                setLoading(false);
                LoaderOpen();
                const sendData = {
                  user_id: localStorage.getItem("user_id"),
                };
                // //console.log(auth)
                var user_id = localStorage.getItem("user_id");
                setUser(user_id);
                var enrooll = localStorage.getItem("count");
                setEnrooll(enrooll);
                axios
                  .post(
                    `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/fetchenrolments`,
                    JSON.stringify(sendData),
                    options,
                    { withCredentials: true }
                  )
                  .then((result) => {
                    setData(result.data);
                    LoaderClose();

                  });
                if (user_data.program_id == 74) {
                  axios.post(
                    `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/fetchIITKUserDetails`,
                    {
                      user_id: user_data.user_id
                    }
                  ).then((res) => {
                    console.log(res, "seee")
                    if (res.data.status == 200) {
                      const { address1, city, country, current_organization, dob, gender, mobile, pincode, linkedin, qualification, university, industry, designation, work_ex, technology } = res.data.data[0]
                      if (!(address1 && city && country && current_organization && dob && gender && mobile && pincode && linkedin && qualification && university && industry && designation && work_ex && technology)) {
                        navigate(`/studentDetails`);
                      }


                    }
                  })
                }
              } else if (user_data.count == "1" || user_data.count == "0") {
                window.localStorage.setItem("user_id", user_data.user_id);
                window.localStorage.setItem("enrol_id", user_data.enrol_id);
                window.localStorage.setItem("category", user_data.category);
                window.localStorage.setItem("count", user_data.count);
                window.localStorage.setItem("username", user_data.firstname);
                window.localStorage.setItem(
                  "lastname",
                  user_data.userlastname
                );
                window.localStorage.setItem("email", user_data.email);
                window.localStorage.setItem("mobile", user_data.mobile);
                window.localStorage.setItem("user_role", user_data.user_role);
                window.localStorage.setItem(
                  "program_id",
                  user_data.program_id
                );
                window.localStorage.setItem("dob", user_data.dob);
                window.localStorage.setItem("batch_id", user_data.batch_id);
                window.localStorage.setItem("term", user_data.term);
                const sendData = {
                  user_id: localStorage.getItem("user_id"),
                };
                axios
                  .post(
                    `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/fetchenrolments`,
                    JSON.stringify(sendData),
                    options,
                    { withCredentials: true }
                  )
                  .then((result) => {
                    console.log(result);
                    window.localStorage.setItem(
                      "program_name",
                      result.data[0].program_name
                    );

                    LoaderClose();

                  });


                setLoading(false);
                if (user_data.program_id == 74) {
                  axios.post(
                    `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/fetchIITKUserDetails`,
                    {
                      user_id: user_data.user_id
                    }
                  ).then((res) => {
                    console.log(res, "seee")
                    if (res.data.status == 200) {
                      const { address1, city, country, current_organization, dob, gender, mobile, pincode, linkedin, qualification, university, industry, designation, work_ex, technology } = res.data.data[0]
                      if (address1 && city && country && current_organization && dob && gender && mobile && pincode && linkedin && qualification && university && industry && designation && work_ex && technology) {
                        navigate(`/Dashboard`);
                      }
                      else {
                        navigate(`/studentDetails`);
                      }
                    }
                  })
                }
              }

            } else {
              // console.log(result.data.messaage)
              // alert(result.data.messaage)
              localStorage.clear();
              Swal.fire({
                title: "Timeout",
                text: "Your Session has been expired please relogin",
                icon: "info",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.href = "https://accredian.com/Login";
                }
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "Timeout",
              text: "Your Session has been expired please relogin",
              icon: "info",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "https://accredian.com/Login";
              }
            });
            localStorage.clear();
          });
      } else {
        // window.location.href = 'http://localhost:3000https://frontend-project2.accredian.com/login';
        // console.log("no token")
        localStorage.clear();
        Swal.fire({
          title: "Timeout",
          text: "Your Session has been expired please relogin",
          icon: "info",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "https://accredian.com/Login";
          }
        });
      }
    };
    fetchToken();
  }, []);

  const handleClickOpen = (
    item
  ) => {
    const { enrol_id, category, program_id, term, program_name, program_code } = item
    //console.log(enrol_id, category);
    window.localStorage.setItem("enrol_id", enrol_id);
    window.localStorage.setItem("category", category);
    window.localStorage.setItem("program_id", program_id);
    window.localStorage.setItem("term", term);
    window.localStorage.setItem("program_name", program_name);
    window.localStorage.setItem("program_code", program_code);
    navigate(`/Dashboard`);
  };
  // if (user == null) {
  //   window.location.href=`https://accredian.com/Login`;
  //  }
  const LoaderOpen = () => {
    setOpen(true);
  };
  const LoaderClose = () => {
    setOpen(false);
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "50% auto",
          }}
        >
          {" "}
          <CircularProgress color="inherit" />
          Loading....
        </Box>
      ) : (
        <Box>
          <Dashborednav />
          {(() => {
            if (programcount == "2" || programcount == "3") {
              return (
                <>
                  <div className="w-full xl:px-12 px-4 py-8 min-h-[80vh] flex items-center justify-center font-circular">
                    <div className="w-full max-w-3xl mx-auto">
                      <h1 className="text-center text-2xl font-semibold">{open ? "Loading Your Dashboards" : "Choose Your Dashboard"}</h1>
                      <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-8 mt-10">
                        {program.map((item, index) => (
                          <ProgramCard key={index} item={item} handleClickOpen={handleClickOpen} />
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              );
            } else {
              return (
                <>
                  <Box sx={{ backgroundColor: "#f3f6f9", pb: 10, mt: -4 }}>
                    {" "}
                    <Dashboard />
                  </Box>
                </>
              );
            }
          })()}
        </Box>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
export default Myaccount;
