import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Badge,
  Popover,
  TextField,
  MenuItem,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LoginDashboard from "../../Navbar/MobileSidebar";
// import Footer from "../../Footer/Footer";
import Sidbar from "../DesktopSidebar";
import MyProgramNav from "./MyProgramNav";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import FEE from "../fee-recepit/fee";
import { input } from "@material-tailwind/react";
import HowTo from "../Howto";


const options = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};
const columns = [
  { id: "Invoice", label: "Invoice#", minWidth: 170 },
  { id: "Date", label: "Date", minWidth: 100 },
  {
    id: "Description",
    label: "Description",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "Amount",
    label: "Amount",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
];
function createData(Invoice, Date, Description, Amount) {
  return { Invoice, Date, Description, Amount };
}


const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
  createData("Italy", "IT", 60483973, 301340),
  createData("United States", "US", 327167434, 9833520),
  createData("Canada", "CA", 37602103, 9984670),
  createData("Australia", "AU", 25475400, 7692024),
  createData("Germany", "DE", 83019200, 357578),
  createData("Ireland", "IE", 4857000, 70273),
  createData("Mexico", "MX", 126577691, 1972550),
  createData("Japan", "JP", 126317000, 377973),
  createData("France", "FR", 67022000, 640679),
  createData("United Kingdom", "GB", 67545757, 242495),
  createData("Russia", "RU", 146793744, 17098246),
  createData("Nigeria", "NG", 200962417, 923768),
  createData("Brazil", "BR", 210147125, 8515767),
];
const Receipt = () => {
  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategory] = useState("");
  const[data,setData]=useState([])
  const[order_id,setOrderID]=useState([])
  const[orders,setOrders]=useState([])
  const[show,setShow]=useState(false)
  const[details,setDetails]=useState({
    // amount:"",
    // product_name:"",
    // username:"",
    // email_id:"",
    // phone_number:"",
    // order_date:"",
    // payment_id:"",
    // receipt:""
  })
  // Use for Navigate on Previous
  let navigate = useNavigate();
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategory(category);
  }, []);
// //console.log(details,"see details")
  useEffect(()=>{
    const sendDataresult = {
      user_id: localStorage.getItem("user_id"),
      // quiz_id:1
    };
    axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/fee-recipt/allorder-details`,
      JSON.stringify(sendDataresult),
      options
    ).then((res)=>{
      //console.log(res.data,"fee data")
      if(res.data){
        if(res.data.status==300){
          setOrderID([])
        }
        else{
          setOrderID(res.data)
        }
       
        axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/fee-recipt/allorders`,
      JSON.stringify(sendDataresult),
      options
    ).then((res)=>{
      //console.log(res.data,"fee data 2")
      setOrders(res.data)
      if(res.data){
        if(res.data.status==300){
          setData([])
        }
        else{
          const final=[...orders,...order_id]
          // //console.log(final,"ppppp")
          setData(final)
        }
       
      }
     
    })
      }
      

     
    })
    
  },[])

  //console.log(data,"finaldata")
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  // if (user == null) {
  //  window.location.href="https://accredian.com/Login"
  // }


  const handleOrderid=(id)=>{
    
    const sendDataresult = {
      id:id
      // quiz_id:1
    };
    axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/fee-recipt/allorderid`,
      JSON.stringify(sendDataresult),
      options
    ).then((res)=>{ 
      //console.log(res.data,"apidata")
      if(res.data){
        setDetails({
          amount:res.data[0].amount,
          product_name:res.data[0].program_name,
          username:res.data[0].firstname+" "+res.data[0].lastname,
          email_id:res.data[0].email,
          phone_number:res.data[0].mobile,
          order_date:date(res.data[0].created_on),
          receipt:res.data[0].receipt,
          state:res.data[0].state,
          payment_id:{
            Payment_ID:res.data[0].razorpay_payment_id,
            Order_ID:res.data[0].razorpay_order_id
          }
        })
      }
     setShow(true)
    })
  }

  const handlePayid=(id)=>{
    
    const sendDataresult = {
      payment_id:id
      // quiz_id:1
    };
    axios
    .post(
      `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/fee-recipt/allpayid`,
      JSON.stringify(sendDataresult),
      options
    ).then((res)=>{ 
      //console.log(res.data,"apidata")
      if(res.data){
        setDetails({
          amount:res.data[0].amount,
          product_name:res.data[0].product_name,
          username:res.data[0].firstname+" "+res.data[0].lastname,
          email_id:res.data[0].email_id,
          phone_number:res.data[0].phone_number,
          order_date:date(res.data[0].order_date),
          state:res.data[0].state,
          payment_id:{
            Payment_ID:res.data[0].payment_id,
            Order_ID:""
          }
          
        })
      }
      setShow(true)
    })
  }

  const date=(input_Date)=>{
  const inputDate = input_Date;
const date = new Date(inputDate);
const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
return formattedDate
  }


  //console.log(details,"uuuu")
  return (
    <>
     {/* <HowTo/> */}
      <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
        <Box sx={{ pt: 8 }}>
          <Grid container>
          <Grid item lg={3} sx={{ display: { xs: "none", lg: "block" } }}>
              <Sidbar />
            </Grid>
            <Grid item lg={9} xs={12} sm={12} md={12} sx={{px:{sm:2,md:2}}}>
             
              {show?(
                  <Box>
                      <FEE details={details} setShow={setShow}/>
                  </Box>
              ):(
                <Box>
                     <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    marginBottom: "0.2rem!important",
                    ml:{lg:3}
                  }}
                >
                  Fee Receipt
                </Typography>
                <Typography sx={{ fontSize: "0.8rem", color: "#a1a5b7",ml:{lg:3} }}>
                  Home
                  <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#a1a5b7", mx: 0.5, }}
                  />
                  Fee Receipt
                </Typography>
              </Box>
              <Box>
                <MyProgramNav />
              </Box>

              <Box sx={{width:{lg:"auto",xs:"400px"},ml:{lg:0,xs:1}}}>
                <Paper
                  sx={{
                    ml:{lg:3},
                    mr:{lg:10},
                    borderRadius: "8px",
                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                    p: 2,
                  }}
                >
                  {localStorage.getItem("category")=="26"?(
                    <Box>
                      <Typography>
                     <b> Disclaimer: </b>Upon making the program payment, fee receipts will be automatically generated by the IIT Kanpur payment portal. Please check your fee receipts sent to you via email from <a style={{textDecoration:"none",color:"#009ef7"}} href='mailto:support_ifacet@iitk.ac.in'>support_ifacet@iitk.ac.in</a>
                      </Typography>
                      <Typography sx={{mt:2}}>
                      If you have any doubts or questions regarding the fee receipts, please send an email to <a style={{textDecoration:"none",color:"#009ef7"}} href='mailto:support_ifacet@iitk.ac.in'>support_ifacet@iitk.ac.in</a>.
                      </Typography>
                    </Box>
                  ):(
                    <Box>
                      <Box>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        marginBottom: "1.3rem!important",
                      }}
                    >
                      Summary
                    </Typography>
                  </Box>
                  <Box>
                    <TableContainer
                      sx={{
                        maxHeight:{lg:440,sm:650,md:910,xs:440},
                        overflow: "scroll",
                        overflowX:"scroll",
                        "&::-webkit-scrollbar": {
                          width: "0.2em",
                        },
                        "&::-webkit-scrollbar-track": {
                          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "rgba(0,0,0,0)",
                          outline: "1px solid #fff",
                        },
                      }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth,fontWeight:"bold" }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order_id
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  // key={row.code}
                                >
                                 <TableCell sx={{color:"#16a6f7",cursor:"pointer" }} onClick={()=>{handleOrderid(row.id)}}  >
                     {row.receipt}
                   </TableCell>
                   <TableCell  >{date(row.created_on)}</TableCell>
                   <TableCell  >{row.description}</TableCell>
                   <TableCell >{row.amount/100}</TableCell>
                                </TableRow>
                              );
                            })}

{orders.length>0&&orders
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  // key={row.code}
                                >
                                 <TableCell sx={{color:"#16a6f7",cursor:"pointer" }} onClick={()=>{handlePayid(row.payment_id)}}  >
                     {row.payment_id}
                   </TableCell>
                   <TableCell  >{date(row.order_date)}</TableCell>
                   <TableCell  >{row.product_name}</TableCell>
                   <TableCell >{row.amount/100}</TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      component="div"
                      count={orders.length+order_id.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Box>
                    </Box>
                  )}
                  
                </Paper>
              </Box>
                </Box>
              )}
             
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default Receipt;
