import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Paper,
  Grid,
  CardMedia,
  Card,
  TextField,
  Button,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LoginDashboard from "../../Navbar/MobileSidebar";
import Sidbar from "../DesktopSidebar";
import RemoveIcon from "@mui/icons-material/Remove";
import PauseIcon from "@mui/icons-material/Pause";
import CheckIcon from "@mui/icons-material/Check";
import parse from "html-react-parser";
import axios from "axios";
import useStyles from "../../../Assets/js/Dashboardstyle/problemstatment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ProblemStatement = () => {
  const [user, setUser] = useState("");
  const classes = useStyles();
  const [Project, setProject] = useState("");
  const [productdescription, setProductdescription] = useState("");
  const [productinstructions, setProductinstructions] = useState("");
  const [enrol_id, setEnrol_id] = useState("");

  const [image, setImage] = useState({ preview: "", data: "" });
  let navigate = useNavigate();

  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  const sendData = {
    productid: localStorage.getItem("productid"),
  };
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");

    setUser(user_id);
    setEnrol_id(enrol_id);

    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/productportfolio/productdescription`,JSON.stringify(sendData),options
      )
      .then((result) => {
        setProject(result.data[0]);
        setProductdescription(parse(result.data[0].product_description));
        setProductinstructions(parse(result.data[0].product_Instructions));
      });
  }, []);
  const handleFileChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(img);
    //console.log(img);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const newoptions = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      },
    };
    const formData = new FormData();
    formData.append("submitionfile", image.data);
    formData.append("enrol_id", enrol_id);
    formData.append("product_id", localStorage.getItem("productid"));

    await axios
      .post(`${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/productportfolio/uploadpdffile`,formData,newoptions
      )
      .then((result) => {
        if (result.data.status == "200") {
          toast.success("Successful Save", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("🦄 Error", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };

  return (
    <>
      <LoginDashboard />
      <ToastContainer />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
        <Box sx={{ pt: { lg: 8, xs: 2 }, px: { xs: 2, lg: 0 } }}>
          <Grid container>
            <Grid item lg={3} sx={{ display: { xs: "none", lg: "block" } }}>
              <Sidbar />
            </Grid>
            <Grid item lg={9} xs={12}>
              <Box sx={{ ml:{lg:2}, mr:{lg:10}}}>
                <Box
                  sx={{
                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                    background: "#fff",
                    mb: 4,
                    p: 3,
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "0.5rem!important",
                    }}
                  >
                    Problem statement
                  </Typography>
                  <Typography
                    sx={{ fontSize: "15px", marginBottom: "0.5rem!important" }}
                  >
                    {productdescription}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                    background: "#fff",
                    mb: 4,
                    p: 3,
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      marginBottom: "0.3rem!important",
                    }}
                  >
                    Instructions
                  </Typography>
                  <Typography className="list" sx={{ fontSize: "15px" }}>
                    {productinstructions}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                    background: "#fff",
                    mb: 4,
                    p: 3,
                    borderRadius: 2,
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    submission
                  </Typography>
                  <form onSubmit={submitForm}>
                    <TextField
                      id=""
                      label="Choose file"
                      variant="outlined"
                      placeholder="Shared Post URL"
                      required
                      fullWidth
                      type="file"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: ".pdf,.docx,.doc" }}
                      onChange={handleFileChange}
                    />
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        mb: 0.3,
                        mr: 3,
                        mt: 2,
                        background: "#009ef7",
                        borderRadius: "8px",
                        textTransform: "none",
                        "&:hover": {
                          background: "#009ef7",
                          borderRadius: "8px",
                          textTransform: "none",
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </form>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default ProblemStatement;
