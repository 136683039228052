import React, { useEffect, useState, useCallback } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  TextField,
  MenuItem,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import { industryinfo } from "../../data/industryinfo";
import LoginDashboard from "../Navbar/MobileSidebar";
import { Country, State, City } from "country-state-city";
import CottageIcon from "@mui/icons-material/Cottage";
import VerifiedIcon from "@mui/icons-material/Verified";
import MailIcon from "@mui/icons-material/Mail";
import Cropper from "react-easy-crop";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Resizer from "react-image-file-resizer";
import ClearIcon from "@mui/icons-material/Clear";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ProfileDetails from '../Dashboard/My-Profile/ProfileDetails.jsx'
import WorkEducation from '../Dashboard/My-Profile/WorkEducation.jsx'
import Sociallinks from "../Dashboard/My-Profile/Sociallinks.jsx";
import Swal from 'sweetalert2'
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    width: "350px",
  },
}));


const StudentDetails = () => {
  const [user, setUser] = useState("");
  const [open, setOpen] = useState(false);
  const [fristname, setFristname] = useState(localStorage.getItem("username"))
  // const [lastname, setLastname] = useState(localStorage.getItem("lastname")?localStorage.getItem("lastname"):"")
  // const [email, setEmail] = useState("")
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [code, setCode] = useState("");
  const [statecode, setCodes] = useState("");
  const [city, setCity] = useState("");
  const [country, setContryname] = useState("");
  const [state, setStatename] = useState("");
  const [username, setUsername] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategorys] = useState("");
  const [backopen, setBackopen] = useState(false);
  const LoaderClose = () => {
    setBackopen(false);
  };
  const LoaderOpen = () => {
    setBackopen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

const Gender = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Others",
    label: "Others",
  },
];

  let navigate = useNavigate();
  const fetchData = async () => {
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/fetchIITKUserDetails`,
      data: {
        user_id: localStorage.getItem("user_id"),
      },
    }).then((res) => {
        if(res.data.status==200){
        setData(res.data.data[0]);
        setCity(res.data.data[0].city);
        setContryname(res.data.data[0].country);
        setStatename(res.data.data[0].state);
        setUsername(res.data.data[0].firstname);
        }
        else{
          console.log("error")
        }
        
        
       
      const arr = Country.getAllCountries();
        const nsate = State.getAllStates();
      for (var i = 0; i < arr.length; i++) {
        if (res.data.data[0].country === arr[i].name) {
          setCode(arr[i].isoCode);
          break;
        }
      };
      
      for (var j = 0; j < nsate.length; j++) {
       
        if (res.data.data[0].state === nsate[j].name) {
          //console.log(nsate[j].name);
          setCodes(nsate[j].isoCode);
          break;
        }
      }
    });
  };
  
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    dob: "",
    gender: "",
    address1: "",
    address2: "",
    pincode: "",
    qualification: "",
    university: "",
    industry: "",
    designation: "",
    current_organization: "",
    work_experience: "",
    technology: "",
    linkedin: "",
    github: "",
    facebook: "",
  });
  const {
    firstname,
    lastname,
    email,
    mobile,
    dob,
    gender,
    address1,
    address2,
    pincode,
    qualification,
    university,
    industry,
    designation,
    current_organization,
    work_ex,
    technology,
    linkedin, github, facebook
  } = data;
  console.log(data,"Seeeee")
  const datahandleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategorys(category);
    fetchData();

  }, []);
  useEffect(() => {
    const getCountries = async () => {
      try {
        const result = await Country.getAllCountries();
        let allCountries = [];
        allCountries = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));

        setCountries(allCountries);
        setSelectedCountry(code);
      } catch (error) {
        setCountries([]);
      }
    };

    getCountries();
  }, [code]);

  useEffect(() => {
    const getStates = async () => {
      try {
        const result = await State.getStatesOfCountry(selectedCountry);
        let allStates = [];
        allStates = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));

        setCities([]);
        setSelectedCity("");
        setStates(allStates);
        setSelectedState(statecode);
      } catch (error) {
        setStates([]);
        setCities([]);
        setSelectedCity("");
      }
    };

    getStates();
  }, [selectedCountry]);

  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState(
          selectedCountry,
          selectedState
        );
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          name,
        }));

        setCities(allCities);
        setSelectedCity(city);
      } catch (error) {
        setCities([])
      }
    };

    getCities();
  }, [selectedState]);

const handleSubmit=async(event)=>{
  event.preventDefault()
  LoaderOpen()
  console.log(
firstname,
    lastname,
    email,
    mobile,
    dob,
    gender,
    address1,
    address2,
    pincode,
    qualification,
    university,
    industry,
    designation,
    current_organization,
    work_ex,
    technology,
    linkedin, github, facebook,
    country,state,city
    )
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/profile/insertIITKUserDetails`,
      data: {
        user_id:localStorage.getItem("user_id"),
        firstname,
        lastname,
        email,
        mobile,
        dob,
        gender,
        address1,
        address2,
        pincode,
        qualification,
        university,
        industry,
        designation,
        current_organization,
        work_ex,
        technology,
        linkedin, 
        github, 
        facebook,
        country,
        state,
        city
      },
    }).then((res)=>{
      LoaderClose()
      if(res.data.status==200){
        toast.success("Details have been saved successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("/Dashboard")
      }
      else{
        Swal.fire({
          title: "Oops!",
          text: "There is an error submitting your details, Please try again later after some time",
          icon: "error",
          
          confirmButtonText: "OK",
        });
      }
    }).catch((err)=>{
      LoaderClose()
      Swal.fire({
        title: "Oops!",
        text: "There is an error submitting your details, Please try again later after some time",
        icon: "error",
        
        confirmButtonText: "OK",
      });
    })
}

  
  return (
    <>
     <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", display:"flex",justifyContent:"center",alignItems:"center" }}>
      <Paper
       sx={{
               
               borderRadius: "8px",
                boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                p:5,
                width:{lg:"60%",xl:"60%",md:"100%",xs:"100%",sm:"100%"}
             }}
      >
        <Typography sx={{ml:2,color:"red",fontSize:"18px"}}><i>Please enter all the mandatory details to access the dashboard</i> <span style={{fontSize:"0.8rem",color:"#000"}}>(<b>Note:</b> All * Fields are mandatory)</span></Typography>
        
      <Box sx={{ mb: 4 }}>
<Paper
      sx={{
        // ml: 3,
        // mr:{lg:10,sm:3,md:3,xs:3},
        borderRadius: "8px",
        boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
        p: 2,
      }}
    >
      <Box>
        <Typography sx={{ fontWeight: "bold",mb:2 }}>Profile Details</Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <Grid container>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="First_Name"
                label="First Name"
                name="firstname"
                fullWidth
                type="text"
                sx={{ pr:{lg:3,sm:3,md:3,xs:0}}}
                onChange={datahandleChange}
                value={firstname}
                required
                onKeyPress={(e) => {
                  if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="Last_Name"
                label="Last Name"
                name="lastname"
                fullWidth
                type="text"
                sx={{ pr:{lg:3,sm:3,md:3,xs:0}}}
                onChange={datahandleChange}
                value={lastname}
                required
                onKeyPress={(e) => {
                  if (new RegExp(/[a-zA-Z]/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="E-mail"
                label="E-mail"
                name="email"
                fullWidth
                type="email"
                sx={{ pr:{lg:3,sm:3,md:3,xs:0}}}
                // sx={{width:{lg:340,sm:340,md:340,xs:"100%"}}}
                onChange={datahandleChange}
                value={email}
                required
                InputProps={{
                  readOnly: true, 
                }}
                onKeyPress={(e) => {
                  if (new RegExp(/^[^<>]*$/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="Phone"
                label="Phone"
                name="mobile"
                type="number"
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0}}}
                // sx={{width:{lg:340,sm:340,md:340,xs:"100%"}}}
                onChange={datahandleChange}
                value={mobile}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="Birth date"
                label="Date of Birth"
                name="dob"
                type="date"
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                InputLabelProps={{ shrink: true }}
                onChange={datahandleChange}
                value={dob}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="gender"
                select
                label="Gender"
                onChange={datahandleChange}
                value={gender}
                name="gender"
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                // InputLabelProps={{ shrink: true }}
              >
                {Gender.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    selected={option.value == gender}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="Addressone"
                label="Address Line 1"
                name="address1"
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={datahandleChange}
                value={address1}
                onKeyPress={(e) => {
                  if (new RegExp(/^[^<>]*$/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="Addresstwo"
                label="Address Line 2"
                name="address2"
                fullWidth
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={datahandleChange}
                value={address2}
                onKeyPress={(e) => {
                  if (new RegExp(/^[^<>]*$/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                select
                id="Country"
                label="Country"
                name="country"
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={(event) => {setSelectedCountry(event.target.value);
                }}
                value={selectedCountry}
              >
                {countries.map(({ isoCode, name }) => (
                  <MenuItem
                    value={isoCode}
                    key={isoCode}
                    onClick={() => {
                      setContryname(name);
                    }}
                  >
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                select
                id="State"
                label="State"
                name="state"
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={(event) => setSelectedState(event.target.value)}
                value={selectedState}
              >
                {states.length > 0 ? (
                  states.map(({ isoCode, name }) => (
                    <MenuItem
                      value={isoCode}
                      key={isoCode}
                      onClick={() => {
                        setStatename(name);
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" key="">
                    No state found
                  </MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                select
                id="City"
                label="City"
                name="city"
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={(event) => setSelectedCity(event.target.value)}
                value={selectedCity}
              >
                {cities.length > 0 ? (
                  cities.map(({ name }) => (
                    <MenuItem
                      value={name}
                      key={name}
                      onClick={() => {
                        setCity(name);
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">No cities found</MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="Pincode"
                label="Pincode"
                name="pincode"
                fullWidth
                required
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={datahandleChange}
                value={pincode}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="Hac"
                  label="Highest Academic Qualification"
                  fullWidth
                  sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                  onChange={datahandleChange}
                  value={qualification}
                  name="qualification"
                  type="text"
                  required
                  // InputLabelProps={{ shrink: false }}
                  onKeyPress={(e) => {
                    if (new RegExp(/^[^<>]*$/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="Hqi"
                  label="Highest Qualification Institution"
                  fullWidth
                  sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                  onChange={datahandleChange}
                  value={university}
                  name="university"
                  type="text"
                  required
                  // InputLabelProps={{ shrink: true }}
                  onKeyPress={(e) => {
                    if (new RegExp(/^[^<>]*$/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="Ci"
                  select
                  label="Current industry"
                  value={industry}
                  onChange={datahandleChange}
                  fullWidth
                  name="industry"
                  sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                  required
                  // InputLabelProps={{ shrink: true }}
                >
                  {industryinfo.map((option) => (
                    <MenuItem
                      key={option.name}
                      value={option.name}
                      selected={option.name == industry}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="Cd"
                  label="Current Designation"
                  fullWidth
                  sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                  onChange={datahandleChange}
                  value={designation}
                  name="designation"
                  type="text"
                  required
                  // InputLabelProps={{ shrink: true }}
                  onKeyPress={(e) => {
                    if (new RegExp(/^[^<>]*$/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="Co"
                  label="Current Organization"
                  placeholder=""
                  fullWidth
                  sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                  onChange={datahandleChange}
                  value={current_organization}
                  name="current_organization"
                  type="text"
                  required
                  // InputLabelProps={{ shrink: true }}
                  onKeyPress={(e) => {
                    if (new RegExp(/^[^<>]*$/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
              </Grid>

              <Grid item lg={6} sm={6} md={6} xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="Twe"
                  label="Total Work Experience (in years)"
                  fullWidth
                  sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                  onChange={datahandleChange}
                  value={work_ex}
                  // InputLabelProps={{ shrink: true }}
                  name="work_ex"
                  type="number"
                  required
                />
              </Grid>
              <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3 }}>
                <TextField
                  id="Et"
                  label="Enter Technology area you work in"
                  fullWidth
                  sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                  onChange={datahandleChange}
                  value={technology}
                  name="technology"
                  type="text"
                  required
                  onKeyPress={(e) => {
                    if (new RegExp(/^[^<>]*$/).test(e.key)) {
                    } else e.preventDefault();
                  }}
                />
              </Grid>
              <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="LinkedIn"
                label="LinkedIn"
                placeholder="URL (https://www.linkedin.com/in/username)"
                fullWidth
                sx={{ pr:{lg:3,sm:3,md:3,xs:0}}}
                onChange={datahandleChange}
                value={linkedin}
                name="linkedin"
                type="text"
                required
                onKeyPress={(e) => {
                  if (new RegExp(/^[^<>]*$/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="GitHub"
                label="GitHub"
                placeholder="URL (https://github.com/username)"
                fullWidth
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={datahandleChange}
                value={github}
                name="github"
                type="text"
                // required
                onKeyPress={(e) => {
                  if (new RegExp(/^[^<>]*$/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3 }}>
              <TextField
                id="Facebook"
                label="Facebook"
                placeholder="URL (https://www.facebook.com/username)"
                fullWidth
                sx={{ pr:{lg:3,sm:3,md:3,xs:0} }}
                onChange={datahandleChange}
                value={facebook}
                name="facebook"
                type="text"
                // required
                onKeyPress={(e) => {
                  if (new RegExp(/^[^<>]*$/).test(e.key)) {
                  } else e.preventDefault();
                }}
              />
            </Grid>
            {/*<Grid item lg={6} sm={6} md={6} xs={6} sx={{ mb: 3 }}>
              <Typography sx={{ fontSize: "0.8rem" }}>
                <b>Note:</b> All * Fields are mandatory
              </Typography>
            </Grid>*/}
            <Grid item lg={12} sm={12} md={12} xs={12} sx={{ mb: 3 }}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  mb: 0.3,
                  float:{lg: "right",sm:"right",md: "right"},
                  mr: 3,
                  background: "#009ef7",

                  borderRadius: "8px",
                  textTransform: "none",

                  "&:hover": {
                    background: "#009ef7",

                    borderRadius: "8px",
                    textTransform: "none",
                  },
                }}
              >
                Save changes
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Paper>
              </Box>

      </Paper>
      
        
      </Box>
      
     <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={backopen}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <ToastContainer/>
    </>
  );
};
export default StudentDetails;
