import api from "./server";

export const requestCallbackService = async (body) => {
  try {
    const { data } = await api.post(`/common_publish/commonPublish`, body);

    return data;
  } catch (error) {
    throw error;
  }
};
