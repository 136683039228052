import React, { useState, useEffect } from "react";
import "./dashboard.css";
import Sidebar from "./Sidebar/Sidebar";
import ShareFeedback from "./Sidebar/Modals/share-feedback";
import Feedback from "./Sidebar/Modals/feedback";


const Sidbar = () => {

  const [isOpen, setIsOpen] = useState(null);
  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategory] = useState("");
  const [term, setTerm] = useState("");

  useEffect(() => {

    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    var term = localStorage.getItem("term");
    setTerm(term);
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategory(category);

  }, []);

  return (
    <>
      <Sidebar setOpen={setIsOpen} />
      <Feedback open={isOpen} setOpen={setIsOpen} />
      <ShareFeedback open={isOpen} setOpen={setIsOpen} />
    </>
  );
};
export default Sidbar;