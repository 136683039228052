import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const google_analytics_key=process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
const root = ReactDOM.createRoot(document.getElementById('root'));

const client = new QueryClient()
ReactGA.initialize(google_analytics_key);
root.render(

  <QueryClientProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </QueryClientProvider>

);
const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
