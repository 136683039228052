import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import ClearIcon from "@mui/icons-material/Clear";
import axios from 'axios';
import Swal from 'sweetalert2';

const Category = [
  {
    value: "material",
    label: "Material",
  },
  {
    value: "quizzes_assignments",
    label: "Quizzes/Assignments",
  },
  {
    value: "projects",
    label: "Projects",
  },
  {
    value: "others",
    label: "Others",
  },
];


const ShareFeedback = ({ open, setOpen }) => {

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isShown, setIsShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [otherFeedback, setOtherFeedback] = useState("");
  const [shareFeedback, setShareFeedback] = useState("");
  const [shareFeedbackOptions,setShareFeedbackOptions]=useState([
    {
      value: "material",
      label: "Material",
    },
    {
      value: "quizzes_assignments",
      label: "Quizzes/Assignments",
    },
    {
      value: "projects",
      label: "Projects",
    },
    {
      value: "others",
      label: "Others",
    },
  ])

  const submitForm = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const payload = {
      user_id: localStorage.getItem("user_id"),
      program_id: localStorage.getItem("program_id"),
      batch_id: localStorage.getItem("batch_id"),
      term_id: localStorage.getItem("term"),
      feedback_for: isShown,
      feedback: feedback,
      faculty_id: shareFeedback.faculty_id,
      faculty_email: shareFeedback.faculty_email,
      faculty_name: shareFeedback.faculty_name,
      username: shareFeedback.username,
      program_name: shareFeedback.program_name,
      batch_name: shareFeedback.batch_name,
      term_name: shareFeedback.term_name,
      user_email: shareFeedback.user_email,
      other_feedback: otherFeedback,

    };
    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/facultyFeedback/faculty-feedback`,
        JSON.stringify(payload),
        options
      )
      .then((result) => {
        if (result.data.status == 200) {
          Swal.fire({
            title: "Success",
            text: "Thank you",
            icon: "success",

            confirmButtonText: "OK",
          });
        }
        setFeedback("");
        setIsLoading(false);
        handleClose();
        setIsShown(false);
        setOtherFeedback("");
        setOpen(null)
      });

  };

  const handleModal = useCallback(async () => {
    setIsLoading(true);
    await axios({
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/headerdetails/fetch_share_feedback_details`,
      data: {
        enrol_id: localStorage.getItem("enrol_id"),
      },
    }).then((res) => {
      setIsLoading(false);
      setShareFeedback(res.data);
      setIsModalOpen(true);
      if (res.data.faculty_email) {
        setShareFeedbackOptions(
          [
            {
              value: "faculty",
              label: "Faculty",
            },
            {
              value: "material",
              label: "Material",
            },
            {
              value: "quizzes_assignments",
              label: "Quizzes/Assignments",
            },
            {
              value: "projects",
              label: "Projects",
            },
            {
              value: "others",
              label: "Others",
            },
          ]
        )
      }
      // else {
      //   Swal.fire({
      //     title: "Info",
      //     text: "Faculty is not assigned for the current term",
      //     icon: "info",
      //     confirmButtonText: "Ok"
      //   })
      // }
    });
  }, []);

  useEffect(() => {
    if (open === "share-feedback") {
      handleModal()
    }
  }, [open, handleModal])

  const handleClose = () => {
    setOpen(null);
    setIsModalOpen(false)
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          width: "600px",
        },
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={submitForm}>
        <DialogTitle id="share-feedback-open">
          {"Share Feedback"}{" "}
          <ClearIcon onClick={handleClose} sx={{ float: "right" }} />
        </DialogTitle>
        <DialogContent>

          <Box>
            <Typography
              sx={{
                fontSize: "1.1rem",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "0.3rem!important",
              }}
            >
              {["18", "19", "20"].includes(localStorage.getItem("category")) ? "Module" : "Term"} {shareFeedback.term_name}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.1rem",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "0.3rem!important",
              }}
            >
              {/* Instructor: {sharefeedback.faculty_name} */}
            </Typography>
          </Box>
          <TextField
            id="Category"
            select
            label={isShown === false ? "Category" : ""}
            // label="Select Program"
            required={true}
            InputLabelProps={{ shrink: false }}
            // defaultValue="faculty"
            fullWidth
            onChange={(e) => setIsShown(e.target.value)}
            sx={{ mb: 2, mt: 2 }}
          >
            {shareFeedbackOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {isShown === "others" && (
            <TextField
              id="other_feedback"
              variant="outlined"
              placeholder="Other Feedback For"
              sx={{ mb: 2 }}
              fullWidth
              value={otherFeedback}
              onChange={(e) => setOtherFeedback(e.target.value)}
            />
          )}

          <TextField
            id="outlined-multiline-static"
            label="Feedback"
            multiline
            rows={3}
            placeholder="Feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ mb: 4, justifyContent: "center" }}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              mb: 0.3,

              mr: 3,
              background: "#009ef7",

              borderRadius: "8px",
              textTransform: "none",

              "&:hover": {
                background: "#009ef7",

                borderRadius: "8px",
                textTransform: "none",
              },
            }}
          >
            Submit
          </Button>

          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              mb: 0.3,
              mr: 3,
              background: "#f5f8fa",
              borderRadius: "8px",
              textTransform: "none",
              color: "#000",

              "&:hover": {
                background: "#f5f8fa",
                borderRadius: "8px",
                textTransform: "none",
                color: "#000",
              },
            }}
          >
            Discard
          </Button>

        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ShareFeedback