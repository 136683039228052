import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LoginDashboard from "../../Navbar/MobileSidebar";
// import Footer from "../../Footer/Footer";
import Sidbar from "../DesktopSidebar";
import MyProgramNav from "./MyProgramNav";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const aser = 0;
const BatchTransfer = () => {
  const [user, setUser] = useState("");
  const [enrol_id, setEnrol_id] = useState("");
  const [category, setCategory] = useState("");

  // Use for Navigate on Previous
  let navigate = useNavigate();
  useEffect(() => {
    // //console.log(auth)
    var user_id = localStorage.getItem("user_id");
    var enrol_id = localStorage.getItem("enrol_id");
    var category = localStorage.getItem("category");
    setUser(user_id);
    setEnrol_id(enrol_id);
    setCategory(category);
  }, []);

  // if (user == null) {
  //  window.location.href="https://accredian.com/Login"
  // }
  return (
    <>
      <LoginDashboard />
      <Box sx={{ backgroundColor: "#f3f6f9", pb: 5 }}>
      <Box sx={{ pt:{lg:8,xs:3},px:{xs:2,lg:0}}}>
          <Grid container>
          <Grid item lg={3} sx={{display:{xs:"none",lg:"block"}}}>
              <Sidbar />
            </Grid>
            <Grid item lg={9} xs={12}>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    marginBottom: "0.2rem!important",
                  }}
                >
                  Batch Transfer
                </Typography>
                <Typography sx={{ fontSize: "0.8rem", color: "#a1a5b7" }}>
                  Hone
                  <FiberManualRecordIcon
                    sx={{ fontSize: "0.4rem", color: "#a1a5b7", mx: 0.5 }}
                  />
                  Batch Transfer
                </Typography>
              </Box>
              <Box>
                <MyProgramNav />
              </Box>
              <Box>
                <Paper
                  sx={{
                    ml:{lg:3},
                    mr:{lg:7},
                    borderRadius: "8px",
                    boxShadow: "0 0 20px 0 rgb(76 87 125 / 2%)",
                    p: 2,
                  }}
                >
                  <Box>
                    {(() => {
                      if (aser == "1") {
                        return (
                          <>
                            <Box
                              display="flex"
                              sx={{ borderBottom: "0.5px solid #eff2f5" }}
                            >
                              <Typography sx={{ fontWeight: "bold" }}>
                                Batch Transfer
                              </Typography>
                              <Typography sx={{ ml: 85 }}>
                                Status (2)
                              </Typography>
                            </Box>
                            <Box sx={{ py: 3 }}>
                              <Box display="flex">
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.4rem", mx: 0.5, mt: 0.5 }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: "0.8rem",
                                    marginBottom: "0.8rem !important",
                                  }}
                                >
                                  Batch transfer will give you an option to
                                  continue your program from a particular term
                                  or restart again from beginning.
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.4rem", mx: 0.5, mt: 0.5 }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: "0.8rem",
                                    marginBottom: "0.8rem !important",
                                  }}
                                >
                                  Once batch transfer fee INR 3000 is paid,
                                  you'll receive an email confirmation in next
                                  24 hours.
                                </Typography>
                              </Box>
                              <Box
                                sx={{ borderTop: "0.5px solid #eff2f5", mt: 5 }}
                                display="flex"
                              >
                                <Box sx={{ ml:{lg:40}}}>
                                  <FormGroup>
                                    <FormControlLabel
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 18 },
                                      }}
                                      control={<Checkbox />}
                                      label={
                                        <Typography
                                          sx={{
                                            fontSize: "0.89rem",
                                            marginBottom: "0.8rem !important",
                                            mt: 2,
                                          }}
                                        >
                                          I accept the{" "}
                                        </Typography>
                                      }
                                    />
                                  </FormGroup>
                                </Box>
                                <Box>
                                  {/* <Link
                                    to="/Policy/admissions-policy"
                                    style={{ textDecoration: "none" }}
                                  > */}
                                  <a href={`https://accredian.com/policies/accredian-policy`} style={{ textDecoration: "none" }} target="_blank">
                                    {" "}
                                    <Typography
                                      sx={{
                                        fontSize: "0.89rem",
                                        marginBottom: "0.8rem !important",
                                        mt: 2,
                                        color: "#009ef7",
                                      }}
                                    >
                                      Terms & Condition
                                    </Typography>
                                  </a>
                                </Box>
                              </Box>
                              <Box>
                                <center>
                                  <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{
                                      mb: 0.3,

                                      mr: 3,
                                      background: "#009ef7",

                                      borderRadius: "8px",
                                      textTransform: "none",

                                      "&:hover": {
                                        background: "#009ef7",

                                        borderRadius: "8px",
                                        textTransform: "none",
                                      },
                                    }}
                                  >
                                    Opt Batch Transfer
                                  </Button>
                                </center>
                              </Box>
                            </Box>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <Box
                              display="flex"
                              sx={{ borderBottom: "0.5px solid #eff2f5" }}
                            >
                              <Typography sx={{ fontWeight: "bold" }}>
                                Re-Enroll <small>from beginning</small>
                              </Typography>
                            </Box>
                            <Box sx={{ py: 3 }}>
                              <Box display="flex">
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.4rem", mx: 0.5, mt: 0.5 }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: "0.8rem",
                                    marginBottom: "0.8rem !important",
                                  }}
                                >
                                  Re-enrolment will give you complete
                                  flexibility to restart your program again.
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.4rem", mx: 0.5, mt: 0.5 }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: "0.8rem",
                                    marginBottom: "0.8rem !important",
                                  }}
                                >
                                  You can restart program from beginning along
                                  with your preferred future cohort.
                                </Typography>
                              </Box>
                              <Box display="flex">
                                <FiberManualRecordIcon
                                  sx={{ fontSize: "0.4rem", mx: 0.5, mt: 0.5 }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: "0.8rem",
                                    marginBottom: "0.8rem !important",
                                  }}
                                >
                                  Once program fee is paid, you'll receive an
                                  email confirmation in next 24 hours.
                                </Typography>
                              </Box>
                              <Box
                                sx={{ borderTop: "0.5px solid #eff2f5", mt: 5 }}
                                display="flex"
                              >
                                <Box sx={{ ml:{lg:40}}}>
                                  <FormGroup>
                                    <FormControlLabel
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 18 },
                                      }}
                                      control={<Checkbox />}
                                      label={
                                        <Typography
                                          sx={{
                                            fontSize: "0.89rem",
                                            marginBottom: "0.8rem !important",
                                            mt: 2,
                                          }}
                                        >
                                          I accept the{" "}
                                        </Typography>
                                      }
                                    />
                                  </FormGroup>
                                </Box>
                                <Box>
                                  {/* <Link
                                    to="/Policy/admissions-policy"
                                    style={{ textDecoration: "none" }}
                                  > */}
                                  <a href={`https://accredian.com/policies/accredian-policy`} style={{ textDecoration: "none" }} target="_blank">
                                    {" "}
                                    <Typography
                                      sx={{
                                        fontSize: "0.89rem",
                                        marginBottom: "0.8rem !important",
                                        mt: 2,
                                        color: "#009ef7",
                                      }}
                                    >
                                      Terms & Condition
                                    </Typography>
                                  </a>
                                </Box>
                              </Box>
                              <Box>
                                <center>
                                  <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{
                                      mb: 0.3,

                                      mr: 3,
                                      background: "#009ef7",

                                      borderRadius: "8px",
                                      textTransform: "none",

                                      "&:hover": {
                                        background: "#009ef7",

                                        borderRadius: "8px",
                                        textTransform: "none",
                                      },
                                    }}
                                  >
                                    Re-Enroll
                                  </Button>
                                </center>
                              </Box>
                            </Box>
                          </>
                        );
                      }
                    })()}
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default BatchTransfer;
