import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import ClearIcon from "@mui/icons-material/Clear";
import axios from 'axios';
import Swal from 'sweetalert2';

const Feedback = ({ open, setOpen }) => {

  const [isLoading, setIsLoading] = useState("");
  const [feedback, setFeedback] = useState("");


  const submitForm = async (e) => {
    LoaderOpen();
    e.preventDefault();

    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    };

    const sandData = {
      enrol_id: localStorage.getItem("enrol_id"),
      message: feedback,
      category: localStorage.getItem("category")
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_NODE_PRODUCTION_DASHBOARD}/feedback/student-feedback`,
        JSON.stringify(sandData),
        options
      )
      .then((result) => {
        if (result.data.status == 200) {
          Swal.fire({
            title: "Success",
            text: "Thank you",
            icon: "success",

            confirmButtonText: "OK",
          });
        }

        LoaderClose();
        setOpen(null);
      });
  };

  const LoaderClose = () => {
    setIsLoading(false);
  };
  const LoaderOpen = () => {
    setIsLoading(true);
  };

  const handleClose = () => {
    setOpen(null);
  };


  return (
    <Dialog
      open={open === "feedback" ? true : false}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="feedback">
        {"Feedback"}{" "}
        <ClearIcon onClick={handleClose} sx={{ float: "right" }} />
      </DialogTitle>
      <form onSubmit={submitForm}>
        <DialogContent>
          <Typography sx={{ fontSize: "0.8rem", fontWeight: "bold" }}>
            Any suggestions or feedback on your dashboard experience?
          </Typography>
          <TextField
            id="outlined-multiline-static"
            label="Comments or suggestions"
            multiline
            rows={3}
            placeholder="Comments or suggestions"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions sx={{ mb: 4, justifyContent: "center" }}>
          <Button
            variant="contained"
            type="submit"
            sx={{
              mb: 0.3,

              mr: 3,
              background: "#009ef7",

              borderRadius: "8px",
              textTransform: "none",

              "&:hover": {
                background: "#009ef7",

                borderRadius: "8px",
                textTransform: "none",
              },
            }}
          >
            Submit
          </Button>

          <Button
            variant="contained"
            onClick={handleClose}
            sx={{
              mb: 0.3,
              mr: 3,
              background: "#f5f8fa",
              borderRadius: "8px",
              textTransform: "none",
              color: "#000",

              "&:hover": {
                background: "#f5f8fa",
                borderRadius: "8px",
                textTransform: "none",
                color: "#000",
              },
            }}
          >
            Discard
          </Button>
        </DialogActions>
      </form>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  )
}

export default Feedback