export const CATEGORIES = [
  { label: "product management", value: "product management" },
  { label: "project management", value: "project management" },
  { label: "data science", value: "data science" },
  { label: "strategy & leadership", value: "strategy & leadership" },
  { label: "digital Transformation", value: "digital Transformation" },
  { label: "business management", value: "business management" },
  { label: "senior management", value: "senior management" },
  { label: "fintech", value: "fintech" },
  { label: "business analytics", value: "business analytics" },
];
