
import React from "react";
import Bookpanel from '../Components/Dashboard/Resource-Center/Bookpanel';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Swal from 'sweetalert2'
const AuthBookpanel = () => {
   const useAuth=()=>{
    const user=localStorage.getItem('user_id')
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*=\s*([^;]*).*$)|^.*$/, '$1');

    if(token){
      return true
    } else {
      localStorage.clear()
      return false
    }
  }
  const auth = useAuth();
  return auth ? <Bookpanel /> : <>
  {
     Swal.fire({
      title: "Timeout",
      text: "Your Session has been expired please relogin",
      icon: "info",
      confirmButtonText: "Ok"
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href="https://accredian.com/Login"
      }
    })
  }
  </>
}
export default AuthBookpanel;
