import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IoWarning } from 'react-icons/io5'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css';
import { useLocation, useSearchParams } from 'react-router-dom'
import { CATEGORIES } from '../../../constants/constants';
import Select from "react-select";
import { useMutation } from '@tanstack/react-query';
import { requestCallbackService } from '../../../services/users.services';
import Swal from 'sweetalert2';

const CallToAction = ({setIsLoading}) => {

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const currentPage = location.pathname === "/" ? "homepage" : location.pathname.replace("/", "")
  const utm_source =
    searchParams.get("utm_source") == undefined
      ? "null"
      : searchParams.get("utm_source");
  const utm_medium =
    searchParams.get("utm_medium") == undefined
      ? "null"
      : searchParams.get("utm_medium");
  const utm_campaign =
    searchParams.get("utm_campaign") == undefined
      ? "null"
      : searchParams.get("utm_campaign");


  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      contact: "",
      query: "",
      program: ""
    },
    mode: "onChange"
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (body) => requestCallbackService(body),
    onSuccess: (result) => {
      if (result.status == "200") {
        Swal.fire({
          title: "Thank You",
          text: "Your request submitted successfully. Our expert will call you soon.",
          icon: "success",
          confirmButtonText: "OK",
        })
      } else {
        Swal.fire({
          title: "Oops!",
          text: "We are facing server issue, please try again in sometime.",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    },
    onError: (error) => {
      console.log(error);
      Swal.fire({
        title: "Oops!",
        text: "We are facing server issue, please try again in sometime.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    },
    onSettled: () => {
      reset("")
    }
  })

  const submitForm = (body) => {

    const { name, contact, email, program } = body

    const payload = {
      phone: contact,
      name,
      email,
      program: program.value,
      source: "Request Callback",
      page: currentPage,
      utm_source,
      utm_medium,
      utm_campaign,
      form_id: "request_callback",
      type: "CTA"
    };
    mutate(payload)
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderRadius: "0",
      borderBottom: errors.vertical ? "2px solid red" : "2px solid rgb(212 212 212)",
      padding: "4px 0",
      backgroundColor: "transparent",
      outline: "none",
      fontSize: "14px",
      lineHeight: "20px",
      boxShadow: state.isFocused ? "none" : provided.boxShadow,
      textTransform: "capitalize"
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "14px",
      textTransform: "capitalize"
    }),
  };

  useEffect(()=>{
    setIsLoading(isPending)
  }, [isPending, setIsLoading])

  return (
    <div className="sticky top-[15%] p-6 bg-white border drop-shadow-sm rounded-lg w-full max-w-[400px] h-fit flex flex-col justify-between ">
      <div className="w-full flex flex-col items-center">
        {/* upper heading */}
        <h1 className="text-xl font-semibold text-neutral-800">Schedule a Call</h1>
        {/* request form */}
        <form className="w-full h-full min-h-[24rem] flex flex-col justify-between" onSubmit={handleSubmit(submitForm)}>
          <div className="w-full">
            {/* name */}
            <div className="relative mt-4">
              <input className={`w-full px-4 py-3 font-normal bg-white border-b-2 outline-none transition disabled:opacity-70 disabled:cursor-not-allowed pl-2 mt-2
${errors["name"] ? "border-rose-500" : "border-neutral-300"} 
${errors["name"] ? "focus:border-rose-500" : "focus:border-blue-500"}`}
                {...register("name", {
                  required: {
                    value: true,
                    message: "Name is required",
                  },
                })}
                id="name"
                type="text"
                placeholder="Name (Required)"
              />
              {errors.name?.message && (
                <div className="text-red-500 text-xs w-full font-medium flex items-center gap-1 mt-1 pl-2">
                  <span className="mt-[-1px]">
                    <IoWarning />
                  </span>
                  <p>{errors.name?.message}</p>
                </div>
              )}
            </div>
            {/* email */}
            <div className="relative mt-4">
              <input className={`w-full px-4 py-3 font-normal bg-white border-b-2 outline-none transition disabled:opacity-70 disabled:cursor-not-allowed pl-2 mt-2
${errors["email"] ? "border-rose-500" : "border-neutral-300"} 
${errors["email"] ? "focus:border-rose-500" : "focus:border-blue-500"}`}
                {...register("email", {
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                })}
                id="email"
                type="email"
                placeholder="Email (Required)"
              />
              {errors.email?.message && (
                <div className="text-red-500 text-xs w-full font-medium flex items-center gap-1 mt-1 pl-2">
                  <span className="mt-[-1px]">
                    <IoWarning />
                  </span>
                  <p>{errors.email?.message}</p>
                </div>
              )}
            </div>
            {/* contact */}
            <div className="relative mt-4">
              <Controller
                name="contact"
                control={control}
                rules={{
                  required: "Contact number is required",
                  minLength: {
                    value: 8,
                    message: "Must provide contact number"
                  }
                }}
                render={({ field }) => (
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      borderBottom: errors.contact ? "2px solid red" : "2px solid rgb(212 212 212)",
                      borderRadius: "0px",
                      padding: "10px",
                      paddingLeft: "55px",
                      outline: "none",
                    }}
                    specialLabel=''
                    enableSearch={true}
                    country={'in'}
                    placeholder="Contact (Required)"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              {errors.contact?.message && (
                <div className="text-red-500 text-xs w-full font-medium flex items-center gap-1 mt-1 pl-2">
                  <span className="mt-[-1px]">
                    <IoWarning />
                  </span>
                  <p>{errors.contact?.message}</p>
                </div>
              )}
            </div>
            {/* vertical */}
            <div className="relative w-full md:flex-1 mt-4">
              <Controller
                name="program"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: "Vertical is required",
                  },
                }}
                render={({ field }) => (
                  <Select
                    isClearable
                    isSearchable
                    placeholder="Choose your vertical"
                    // placeholder="Choose your vertical"
                    styles={customStyles}
                    {...field}
                    options={CATEGORIES}
                  />
                )}
              />
              <div className="">
                {errors.program?.message && (
                  <div className="text-red-500 text-xs w-full font-medium flex items-center gap-1 mt-1 pl-2">
                    <span className="mt-[-1px]">
                      <IoWarning />
                    </span>
                    <p>{errors.program?.message}</p>
                  </div>
                )}
              </div>
            </div>
            {/* policy */}
            <div className="w-full mt-6 text-center">
              <h3 className="text-xs font-medium">By submitting the form, you agree to our <a rel='noreferrer' target='_blank' href="https://accredian.com/terms" className="text-universal">Terms and Conditions</a> and our <a target='_blank' rel='noreferrer' href="https://accredian.com/terms/privacy" className="text-universal">Privacy Policy</a>.</h3>
            </div>
          </div>
          {/* submit button */}
          <button disabled={isPending} className={`hover:bg-darkBlue text-base relative mt-6 px-4 py-2 bg-universal font-medium text-white w-full flex justify-center rounded-lg drop-shadow-md`} type="submit">
            {isPending ? (
              "loading..."
            ) : "Request callback"}
          </button>
        </form>
      </div>
    </div>
  )
}

export default CallToAction